import React, { useState } from "react";
import FormattedPhoneNumber from "../../shared/components/util/FormattedPhoneNumber";

const ChoosePhoneNumber = ({ phoneNumbers, onChange }) => {
	const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");

	const handleChange = (value) => {
		setSelectedPhoneNumber(value);
		onChange(value);
	};

	return (
		<div className="w-full">
			<div className="mt-8 mb-0">
				<label className="block text-sm font-medium leading-6 text-gray-900">
					Welk telefoonnummer wil je gebruiken?
				</label>
				<fieldset className="mt-4">
					<div className="space-y-2">
						{phoneNumbers.map((phone, index) => (
							<div key={index} className="flex items-center">
								<input
									id={"phone_" + phone.tel}
									name="chosenPhoneNumber"
									type="radio"
									value={phone.tel}
									checked={selectedPhoneNumber === phone.tel}
									onChange={() => handleChange(phone.tel)}
									className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 cursor-pointer"
								/>
								<label
									htmlFor={"phone_" + phone.tel}
									className="ml-3 block text-sm text-black font-medium cursor-pointer"
								>
									<FormattedPhoneNumber
										phoneNumber={phone.tel}
									/>
									{phone.label && (
										<span className="bg-slate-200 text-slate-900 px-2 py-0.5 rounded-full text-xs ml-2">
											{phone.label}
										</span>
									)}
								</label>
							</div>
						))}
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export default ChoosePhoneNumber;
