import React from "react";

import {
	ArchiveBoxIcon,
	ChartBarIcon,
	PlusIcon,
} from "@heroicons/react/16/solid";
import { Link } from "react-router-dom";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import SlotsTable from "../components/SlotsTable";
import Button from "../../shared/components/FormElements/Button";
import SlotsStats from "../components/SlotsStats";

const AllSlotsPage = () => {
	return (
		<>
			<Container>
				<div className="flex justify-between mb-10">
					<div>
						<div className="flex items-end">
							<Heading className="mt-0">Lege plekken</Heading>
						</div>
					</div>

					<div className="flex items-center">
						<Button to="/gaten/nieuw" iconPrefix={PlusIcon}>
							Lege plek melden
						</Button>
					</div>
				</div>

				<SlotsStats />

				<SlotsTable />
				<div className="mt-6">
					<Link
						to="/gaten/archief"
						className="text-slate-500 hover:text-slate-800 transition-colors duration-200 flex items-center w-fit"
					>
						<ArchiveBoxIcon className="w-4 h-4 mr-2" />
						Bekijk gearchiveerde lege plekken
					</Link>
				</div>
			</Container>
		</>
	);
};

export default AllSlotsPage;
