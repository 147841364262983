import React from "react";
import { DatePicker as AntDatePicker, ConfigProvider } from "antd";
import { useField, useFormikContext } from "formik";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/nl_NL";

import "dayjs/locale/nl";
import Label from "./Label";

const DatePicker = ({
	label,
	description,
	dateFormat = "DD/MM/YYYY",
	defaultValue,
	prefill,
	...props
}) => {
	const [field, meta, helpers] = useField(props);
	const { setFieldValue } = useFormikContext();

	const handleChange = (value) => {
		// console.log(value.format("YYYY-MM-DD"));
		setFieldValue(props.name, value);
	};

	return (
		<div
			className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}
		>
			<div className="col-span-full">
				<Label
					label={label}
					description={description}
					name={props.name}
					optional={props.optional}
					tooltip={props.tooltip}
				/>
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: "#7F56D9",
						},
					}}
				>
					<AntDatePicker
						{...field}
						{...props}
						minDate={dayjs()}
						locale={locale}
						format={dateFormat}
						onChange={handleChange}
						placeholder="Kies datum"
						defaultValue={prefill && dayjs(prefill, "DD-MM-YYYY")}
					/>
				</ConfigProvider>
				{meta.touched && meta.error ? (
					<div className="text-red-600 text-sm pt-2">
						{meta.error}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default DatePicker;
