import React from "react";
import Heading from "../../shared/components/UIElements/Heading";
import NewPatientForm from "../components/NewPatientForm";
import SidePanel from "../../shared/components/UIElements/SidePanel";
import { Dialog } from "@headlessui/react";

const NewPatientModal = ({
	open,
	setOpen,
	reload,
	initialValues,
	fromDesktopApp = false,
}) => {
	return (
		<SidePanel open={open} setOpen={setOpen} blank>
			<div className="flex flex-col px-6 pt-16">
				<div className="flex items-center justify-between">
					{!fromDesktopApp && (
						<Dialog.Title className="text-2xl font-bold leading-6 text-black pb-2">
							Patiënt toevoegen
						</Dialog.Title>
					)}
				</div>
			</div>
			<div className="">
				<NewPatientForm
					setOpen={setOpen}
					reload={reload}
					initialValues={initialValues}
					fromDesktopApp={fromDesktopApp}
				/>
			</div>
		</SidePanel>
	);
};

export default NewPatientModal;
