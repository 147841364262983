import React from "react";
import { useField } from "formik";
import FormattedPhoneNumber from "../util/FormattedPhoneNumber";

const Checkbox = ({
	label,
	description,
	tag,
	renderAsPhoneNumber = false,
	...props
}) => {
	// useField now returns a boolean value, true if checked, false otherwise.
	const [field, meta, helpers] = useField(props);

	const handleCheckboxChange = (event) => {
		helpers.setValue(event.target.checked);
	};

	return (
		<div className={props.width ? "w-" + props.width : "w-full"}>
			<div
				className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}
			>
				<div className={"col-span-full"}>
					<div className="space-y-4">
						<div className="relative flex items-start">
							<label className="flex items-center">
								<input
									{...props}
									checked={field.value}
									onChange={handleCheckboxChange}
									type="checkbox"
									className="focus:ring-brand-500 h-4.5 w-4.5 text-brand-600 border-gray-300 rounded cursor-pointer"
								/>
								<div className="ml-3 text-sm cursor-pointer">
									<div className="flex items-center gap-2">
										<span className="text-black font-medium">
											{renderAsPhoneNumber ? (
												<FormattedPhoneNumber
													phoneNumber={label}
												/>
											) : (
												label
											)}
										</span>
										{tag && (
											<span className="bg-slate-200 text-slate-900 px-2 py-0.5 rounded-full text-xs">
												{tag}
											</span>
										)}
									</div>
									<p className="text-sm leading-5 text-gray-500">
										{description}
									</p>
								</div>
							</label>
						</div>
						{meta.touched && meta.error && (
							<div className="error mt-2">{meta.error}</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Checkbox;
