import React from "react";
import { CheckIcon } from "@heroicons/react/16/solid";

const CheckList = ({ items }) => {
	return (
		<div className="space-y-2">
			{items.map((item, index) => (
				<div key={index} className="flex items-center gap-x-1">
					<CheckIcon className="size-5 text-green-600" />
					<span className="font-semibold text-green-600">{item}</span>
				</div>
			))}
		</div>
	);
};

export default CheckList;
