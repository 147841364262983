import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../shared/hooks/useFetch";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";

import CheckList from "../../shared/components/UIElements/CheckList";
import MiniCopyButton from "../../shared/components/FormElements/MiniCopyButton";

const PatientInfoEmail = () => {
	const [practice, setPractice] = useState("");
	const { user } = useUser();
	const fetchPractice = useFetch();

	useEffect(() => {
		const getPractice = async () => {
			const practiceReq = await fetchPractice(
				`/api/practice/user/${user.id}`,
				"GET"
			);
			setPractice(practiceReq);
		};

		getPractice();
	}, [fetchPractice, user]);

	const encodedPractice = encodeURIComponent(practice);
	const link = `https://snelterecht.nl/patienten/?pt=${encodedPractice}`;

	const [email, setEmail] = useState("");

	const generateEmailContent = (practice) => {
		return `PS. Wilt u toch graag eerder langskomen? Ondanks lange wachttijden kunt u snel een afspraak krijgen in de praktijk via onze Snelterecht lijst. We sturen u dan een WhatsApp bericht wanneer er plekken in de agenda zijn vrijgekomen. Wilt u toegevoegd worden aan deze lijst? Neem dan contact op door te bellen naar ${
			practice.phoneNumber || "...."
		} of mailen naar ${practice.settings?.emailAddress || "...."}`;
	};

	useEffect(() => {
		if (practice) {
			setEmail(generateEmailContent(practice));
		}
	}, [practice]);

	return (
		<Container>
			<MiniBackButton to="/help" />
			<Heading>Tekst voor onderaan de afspraakbevestiging</Heading>
			<p className="text-gray-600 mt-2 mb-6">
				Hiermee breng je patiënten op de hoogte dat ze ook eerder een
				afspraak kunnen krijgen. Een goed gevulde Snelterecht lijst
				zorgt ervoor dat je meer lege plekken in de agenda kunt vullen!
			</p>

			<CheckList
				items={[
					"Voor in de afspraakbevestiging die verstuurd wordt vanuit je praktijksoftware",
				]}
			/>

			<div className="mt-10">
				{practice && (
					<p className="text-slate-700 w-1/2 bg-white p-4 rounded-xl border-slate-300 border shadow-sm">
						{generateEmailContent(practice)
							.split("\n")
							.map((line, index) => (
								<React.Fragment key={index}>
									{line}
									<br />
								</React.Fragment>
							))}
					</p>
				)}
			</div>
			<MiniCopyButton
				text={email}
				includeText={true}
				includeLabel={true}
				classNames="mt-4 scale-110"
			/>
		</Container>
	);
};

export default PatientInfoEmail;
