import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CheckIcon, PencilSquareIcon } from "@heroicons/react/16/solid";
import { toast } from "react-toastify";

import lightbulb from "../../assets/Lightbulb.png";

import useFetch from "../../shared/hooks/useFetch";
import Button from "../../shared/components/FormElements/Button";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";
import UpdatedSuccessfullyPage from "../components/UpdatedSuccessfully";
import AvailabilityDetail from "../../patients/components/AvailabilityDetail";

const AvailabilityCheckbox = ({ day, time, availability, setAvailability }) => {
	const handleChange = () => {
		const newAvailability = { ...availability };
		newAvailability[day][time] = !newAvailability[day][time];
		setAvailability(newAvailability);
	};

	return (
		<input
			type="checkbox"
			checked={availability[day][time]}
			onChange={handleChange}
			className={`focus:ring-green-500 w-12 h-7 text-green-600 border-gray-300 rounded-lg cursor-pointer ${
				!availability[day][time] ? "bg-red-500 relative" : ""
			}`}
			style={{
				backgroundImage:
					!availability[day][time] && "url('/white-x.svg')",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
			}}
		/>
	);
};

const days = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const daysLabels = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"];
const times = ["morning", "afternoon"]; // Removed "evening"
const timeLabels = ["Ochtend", "Middag"]; // Display labels for times
const timeSubLabels = ["t/m 12u", "12u - 17u"]; // Removed evening sublabel

const Availability = () => {
	const fetchAvailability = useFetch();
	const { patientId } = useParams();

	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const key = queryParams.get("key");

	const [isUpdated, setIsUpdated] = useState(false);

	// Initialize availability state
	const [availability, setAvailability] = useState(
		days.reduce((acc, day) => {
			acc[day] = { morning: true, afternoon: true, evening: true };
			return acc;
		}, {})
	);

	// Toggle all times for a given day
	const handleDayToggle = (day) => {
		const dayAvailability = availability[day];
		const isAnyFalse = Object.values(dayAvailability).some(
			(value) => !value
		);
		const newDayAvailability = Object.keys(dayAvailability).reduce(
			(acc, time) => {
				acc[time] = isAnyFalse; // Set all to true if any are false, otherwise toggle all to false
				return acc;
			},
			{}
		);

		setAvailability({ ...availability, [day]: newDayAvailability });
	};

	// Get availability from API
	useEffect(() => {
		const getPatient = async () => {
			const response = await fetchAvailability(
				`/api/public/patient/${patientId}/`
			);
			setAvailability(response.availability);
		};

		getPatient();
	}, [fetchAvailability, patientId]);

	const saveAvailability = async () => {
		await fetchAvailability(
			`/api/public/patient/${patientId}/availability?key=${key}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ availability: availability }),
			}
		);

		setIsUpdated(true);
	};

	if (isUpdated) {
		return (
			<UpdatedSuccessfullyPage>
				<div className="mt-6">
					<MiniBackButton to={`/c/pt/${patientId}/?key=${key}`} />
					<AvailabilityDetail
						availability={availability}
						forPatient
					/>
				</div>
				<Link
					className="mt-10 flex items-center font-semibold text-brand-intense"
					to={`/c/pt/${patientId}/?key=${key}`}
				>
					<PencilSquareIcon className="size-4 mr-2" />
					Aanpassen
				</Link>
			</UpdatedSuccessfullyPage>
		);
	}

	return (
		<div className="flex flex-col h-[100vh] justify-between px-6 pt-24">
			<div>
				<MiniBackButton to={`/c/pt/${patientId}?key=${key}`} />
				<h1 className="text-2xl font-bold">
					Zet een rood kruisje bij de dagdelen waarvoor u géén
					uitnodiging wilt krijgen
				</h1>
				<div className="bg-[#fffef6] p-4 rounded-xl border border-yellow-300 mt-6 shadow shadow-yellow-200 flex items-center">
					<img
						src={lightbulb}
						alt="lightbulb"
						className="size-7 mr-3"
					/>
					<p className="text-gray-800 text-sm">
						Doe dit alleen bij de dagdelen waarvan u{" "}
						<strong>zeker</strong> bent dat u niet kunt. U kunt een
						uitnodiging immers altijd nog afwijzen.
					</p>
				</div>

				<table className="mt-8">
					<thead>
						<tr>
							<th></th>
							{times.map((time, index) => (
								<th key={time} className="px-3">
									<div className="flex flex-col">
										{timeLabels[index]}{" "}
										<span className="text-sm font-normal">
											({timeSubLabels[index]})
										</span>
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{days.map((day, dayIndex) => (
							<tr key={day}>
								<th className="text-left ">
									<button
										onClick={() => handleDayToggle(day)}
										className="mr-4"
									>
										{daysLabels[dayIndex]}
									</button>
								</th>
								{times.map((time) => (
									<td
										key={`${day}-${time}`}
										className="text-center py-4"
									>
										<AvailabilityCheckbox
											day={day}
											time={time}
											availability={availability}
											setAvailability={setAvailability}
										/>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className="sticky bottom-0 w-full py-3 border-t border-gray-200 bg-white">
				<Button
					fullWidth
					onClick={saveAvailability}
					iconPrefix={CheckIcon}
					disabled={
						!Object.values(availability).some((day) =>
							Object.values(day).some(
								(isAvailable) => isAvailable
							)
						)
					}
				>
					Beschikbaarheid opslaan
				</Button>
			</div>
		</div>
	);
};

export default Availability;
