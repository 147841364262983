import React from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";

import useFetch from "../../../shared/hooks/useFetch";
import useCustomConfirm from "../../../shared/hooks/useCustomConfirm";

const DeleteBehandelaar = ({ behandelaarId, name, reload }) => {
	const fetch = useFetch();
	const [confirm, ConfirmModal] = useCustomConfirm();

	const handleDelete = async () => {
		if (
			await confirm({
				title: "Weet je het zeker?",
				description: `Weet je zeker dat je ${name} wilt verwijderen als behandelaar?`,
				cta: "Ja, ik weet het zeker",
				cancel: "Annuleren",
				warning: true,
			})
		) {
			try {
				await fetch(`/api/behandelaars/${behandelaarId}`, {
					method: "DELETE",
				});
				reload();
				toast.success(`${name} is verwijderd als behandelaar`, {
					position: "bottom-center",
					theme: "light",
					hideProgressBar: true,
					autoClose: 2000,
					icon: (
						<TrashIcon
							className=" text-red-500"
							aria-hidden="true"
						/>
					),
				});
			} catch (error) {
				console.error("Failed to delete behandelaar:", error);
			}
		}
	};

	return (
		<>
			<button onClick={handleDelete}>
				<TrashIcon className="h-5 w-5 ml-2 inline" />
			</button>
			<ConfirmModal />
		</>
	);
};

export default DeleteBehandelaar;
