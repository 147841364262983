import { useEffect, useState, useRef } from "react";
import { Widget } from "@typeform/embed-react";
import { useLocation } from "react-router-dom";

const RequestAccount = () => {
	const [uid, setUid] = useState("");
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const via = queryParams.get("via");
	const widgetRef = useRef(null);

	useEffect(() => {
		const generatedUid = generateUid();
		setUid(generatedUid);
	}, []);

	useEffect(() => {
		if (widgetRef.current) {
			widgetRef.current.focus();
		}
	}, [uid]);

	const generateUid = () => {
		const chars =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let uid = "";
		for (let i = 0; i < 25; i++) {
			const randomIndex = Math.floor(Math.random() * chars.length);
			uid += chars[randomIndex];
		}
		return uid;
	};

	return (
		<>
			<div ref={widgetRef}>
				<Widget
					id="UD35bmXd"
					className="h-screen"
					hidden={{
						uid: uid,
						via: via,
					}}
				/>
			</div>
		</>
	);
};

export default RequestAccount;
