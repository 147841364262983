import React from "react";
import { useUser } from "@clerk/clerk-react";
import Button from "../../../shared/components/FormElements/Button";
import { PlusIcon } from "@heroicons/react/16/solid";

const PracticeInfoButton = () => {
	const { user } = useUser();

	return (
		<Button
			to={`https://dentiva.typeform.com/to/R87GJ0kR#user_id=${user.id}`}
			classNames="w-fit mt-4"
			iconPrefix={PlusIcon}
			target="_blank"
		>
			Voeg je praktijkinformatie toe
		</Button>
	);
};

export default PracticeInfoButton;
