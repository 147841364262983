import React, { useState, useEffect } from "react";

import useFetch from "../../shared/hooks/useFetch";

import Container from "../../shared/components/UIElements/Container";
import { useClerk } from "@clerk/clerk-react";
import Heading from "../../shared/components/UIElements/Heading";
import SkeletonLoading from "../../shared/components/UIElements/SkeletonLoading";
import SlotsTable from "../components/SlotsTable";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";
import SlotsStats from "../components/SlotsStats";

const SlotsArchivePage = () => {
	const [slots, setSlots] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useClerk();
	const fetchSlots = useFetch();

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetchSlots(
				`/api/slots/user/${user.id}/archive`
			);
			setSlots(response);
			setIsLoading(false);
		};
		fetchData();
	}, [fetchSlots, user.id]);

	return (
		<Container>
			<MiniBackButton to="/gaten/" />
			<Heading>Archief: Lege plekken</Heading>
			<p className="mt-2 text-gray-600">
				Dit zijn alle lege plekken in het verleden die je hebt gemeld in
				Snelterecht
			</p>

			<div>
				<SlotsTable
					initialSlots={slots}
					initialIsLoading={isLoading}
					itemsClickable={false}
					shouldFetch={false}
					archive={true}
				/>
			</div>
		</Container>
	);
};

export default SlotsArchivePage;
