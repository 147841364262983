import React from "react";

import { EnvelopeIcon } from "@heroicons/react/24/outline";

const AuthFooter = () => {
	return (
		<>
			<p className="text-gray-500 text-sm">
				© Snelterecht - Onderdeel van Dentiva
			</p>

			<a
				className="mt-2 sm:mt-0 flex text-sm text-gray-500 hover:text-brand-600 transition ease-in-out duration-300"
				href="mailto:team@dentiva.nl"
			>
				<EnvelopeIcon className="text-gray-400 w-5 h-auto mr-1" />
				team@dentiva.nl
			</a>
		</>
	);
};

export default AuthFooter;
