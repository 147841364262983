import React from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

const ErrorBoundary = ({ children }) => {
	return (
		<SentryErrorBoundary
			fallback={({ error, componentStack, resetError }) => {
				console.log("Error boundary triggered");
				// Log the error to Sentry
				Sentry.captureException(error, { extra: { componentStack } });
				// Show a toast notification
				toast.error("An unexpected error occurred. Please try again.");
				// Optionally, you can reset the error boundary state
				resetError();
				// Return the children to keep the user on the same page
				return children;
			}}
		>
			{children}
		</SentryErrorBoundary>
	);
};

export default ErrorBoundary;
