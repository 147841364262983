import { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";

import useFetch from "../../shared/hooks/useFetch";
import { useTaskContext } from "../context/TaskContext";

const TasksCounter = () => {
        const [notifs, setNotifs] = useState([]);
        const fetchNotifications = useFetch();
        const { user } = useUser();
        const { tasksUpdated } = useTaskContext();

        useEffect(() => {
                const getNotifications = async () => {
                        const response = await fetchNotifications(
                                `/api/tasks/user/${user.id}/open`,
                        );
                        setNotifs(response.length);
                };

                getNotifications();
        }, [tasksUpdated]);

        if (notifs && notifs > 0) {
                return (
                        <span className="inline-flex h-fit items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                {notifs}
                        </span>
                );
        }
};

export default TasksCounter;
