import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";

import useFetch from "../../shared/hooks/useFetch";
import TasksListItem from "./TasksListItem";
import EmptyState from "../../shared/components/Tables/EmptyState";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

const TasksList = () => {
	const [tasks, setTasks] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const { user } = useUser();
	const fetchTasks = useFetch();

	useEffect(() => {
		const getTasks = async () => {
			const results = await fetchTasks(`/api/tasks/user/${user.id}`);
			setTasks(results);
			setIsLoading(false);
		};
		getTasks();
	}, [fetchTasks]);

	if (isLoading) {
		return (
			<>
				{Array(4)
					.fill()
					.map((_, index) => (
						<div
							key={index}
							className="flex items-center space-x-4 pb-6 animate-pulse"
						>
							<div className="rounded-full bg-gray-200 size-9"></div>
							<div className="flex-1 space-y-2">
								<div className="h-4 bg-gray-200 rounded w-1/3"></div>
								<div className="h-3 bg-gray-200 rounded w-1/4"></div>
							</div>
						</div>
					))}
			</>
		);
	}

	if (!isLoading && tasks.length === 0) {
		return (
			<EmptyState
				title="Je bent helemaal up-to-date!"
				description="Er zijn geen taken gevonden die je nog moet afronden."
				icon={CheckCircleIcon}
			/>
		);
	}

	return (
		<>
			{tasks.map((task) => (
				<TasksListItem
					key={task._id}
					task={task}
					setTasks={setTasks}
					isChecked={task.isChecked}
				/>
			))}
		</>
	);
};

export default TasksList;
