import React from "react";

import { SignUp } from "@clerk/clerk-react";

const SignUpForm = () => {
	// const queryParams = new URLSearchParams(window.location.search);
	// const email = queryParams.get("email");
	// const firstName = queryParams.get("firstName");
	// const lastName = queryParams.get("lastName");
	// const praktijkNaam = queryParams.get("praktijkNaam");
	// const praktijkAdres = queryParams.get("praktijkAdres");
	// const praktijkPlaats = queryParams.get("praktijkPlaats");
	// const praktijkPostcode = queryParams.get("praktijkPostcode");

	return (
		<div className="mt-signup">
			<SignUp
				appearance={{
					elements: {
						header: "hidden",
						card: "w-full p-0 m-0 bg-transparent shadow-none",
						rootBox: "w-full",
						formFieldInput:
							"rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6",
						formButtonPrimary:
							"bg-brand-600 hover:bg-brand-700 hover:scale-105 transition ease-in-out duration-300 rounded-lg normal-case text-base shadow-sm font-semibold tracking-normal py-3 text-white",
						footerActionLink: "text-brand-600 hover:text-brand-700",
						// footer: "hidden",
						formField__emailAddress: "hidden",
						// formField__firstName: "hidden",
						// formField__lastName: "hidden",
					},
				}}
				localization={{
					formFieldErrorText_emailAddress:
						"Dat e-mailadres is al in gebruik. Probeer een ander.",
				}}
				// initialValues={{
				// 	emailAddress: email,
				// 	firstName: firstName,
				// 	lastName: lastName,
				// }}
				// unsafeMetadata={{
				// 	praktijkNaam: praktijkNaam,
				// 	initialPraktijkNaam: praktijkNaam,
				// 	praktijkAdres: praktijkAdres,
				// 	praktijkPostcode: praktijkPostcode,
				// 	praktijkPlaats: praktijkPlaats,
				// }}
			/>
		</div>
	);
};

export default SignUpForm;
