import React from "react";

const SkeletonLoading = ({ rows = 8, columns = 3, components = [] }) => {
	return (
		<tbody className="bg-white divide-y divide-gray-200">
			{Array(rows)
				.fill()
				.map((_, rowIndex) => (
					<tr key={rowIndex}>
						{Array(columns)
							.fill()
							.map((_, colIndex) => (
								<td
									className="animate-pulse px-6 py-4 whitespace-nowrap"
									key={colIndex}
								>
									{components[colIndex] ? (
										components[colIndex]
									) : (
										<div className="h-4 bg-gray-200 rounded"></div>
									)}
								</td>
							))}
					</tr>
				))}
		</tbody>
	);
};

export default SkeletonLoading;
