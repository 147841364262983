import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "../../shared/hooks/useFetch";
import { toast } from "react-toastify";
import {
	PhoneIcon,
	PencilSquareIcon,
	InformationCircleIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import FormattedPhoneNumber from "../../shared/components/util/FormattedPhoneNumber";
import SkeletonLoadingItem from "../../shared/components/UIElements/SkeletonLoadingItem";
import Checkbox from "../../shared/components/FormElements/Checkbox";
import RadioGroup from "../../shared/components/FormElements/RadioGroup";
import ChoosePhoneNumber from "./ChoosePhoneNumber";

const PrefilledFromDesktopInfo = ({
	setStep,
	setPrefilledValues,
	values,
	setIsLoadingIntegrationResult,
	setSelectedPhoneNumbers,
	setFoundPhoneNumbers,
	setFieldValue,
}) => {
	const location = useLocation();
	const createPatient = useFetch();
	const [isLoading, setIsLoading] = useState(true);
	const [software, setSoftware] = useState("");
	const [response, setResponse] = useState(null);
	const [dataFetched, setDataFetched] = useState(false);
	const [error, setError] = useState(null);
	const [phoneNumbers, setPhoneNumbers] = useState([]); // State to store phone numbers

	// Polling function to fetch integration data
	const fetchIntegrationData = async (id, key, intervalId) => {
		try {
			const response = await createPatient(
				`/api/desktop/integration-results/${id}?key=${key}`
			);

			if (
				response.patientData.firstName ||
				response.patientData.lastName
			) {
				setResponse(response);

				if (response.patientData.phoneNumbers.length === 1) {
					setPrefilledValues({
						...response.patientData,
						phoneNumber: response.patientData.phoneNumbers[0].tel,
					});
				} else {
					setPrefilledValues({
						...response.patientData,
						phoneNumber: null,
					});
				}
				setPhoneNumbers(response.patientData.phoneNumbers);
				setFoundPhoneNumbers(response.patientData.phoneNumbers);
				clearInterval(intervalId);
				setIsLoading(false);
				setIsLoadingIntegrationResult(false);
				setDataFetched(true); // Set dataFetched to true
			}

			if (!response.isLoading && response.isRelevant === false) {
				setError(
					"Geen patiëntdata gevonden. Weet je zeker dat de patiëntenkaart open stond?"
				);
				setDataFetched(true);
				setIsLoading(false);
			} else if (
				!response.isLoading &&
				response.patientData &&
				response.patientData.firstName === "" &&
				response.patientData.lastName === "" &&
				response.patientData.patientnummer === "" &&
				response.patientData.phoneNumbers.length === 0
			) {
				setError("Geen patiëntdata gevonden");
				setIsLoading(false);
			}
		} catch (error) {
			console.error("Error fetching integration data:", error);
			toast.error("Fout bij het ophalen van de patiëntgegevens", {
				position: "bottom-center",
			});
		}
	};

	// Use effect to start polling if there's an id in the URL
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const documentId = queryParams.get("id");
		const softwareParam = queryParams.get("software");
		const key = queryParams.get("key");

		const softwareDictionary = {
			oase: "Oase",
			novadent: "Novadent",
			exquise_classic: "Exquise Classic",
			exquise_next_gen: "Exquise Next Gen",
		};
		setSoftware(softwareDictionary[softwareParam] || "praktijksoftware");

		if (documentId && isLoading && !dataFetched) {
			// Check if data has already been fetched
			const startPolling = () => {
				const intervalId = setInterval(() => {
					fetchIntegrationData(documentId, key, intervalId);
				}, 1000); // Poll every 1 second

				return () => clearInterval(intervalId); // Cleanup interval on component unmount
			};

			const timeoutId = setTimeout(startPolling, 1000); // Wait 2 seconds before starting to poll

			return () => {
				clearTimeout(timeoutId); // Cleanup timeout on component unmount
			};
		}
	}, [location.search, isLoading, dataFetched]); // Add dataFetched to dependency array

	if (!dataFetched) {
		return (
			<div className="mb-6">
				<div className="bg-slate-200 rounded-xl p-4 flex justify-between w-fit">
					<div className="pr-8 space-y-2">
						<SkeletonLoadingItem
							width="w-40"
							height="h-6"
							color="slate-400"
						/>

						<SkeletonLoadingItem
							width="w-16"
							height="h-4"
							color="slate-300"
						/>

						<p className="flex items-center">
							<PhoneIcon className="size-4 mr-2 text-slate-600" />
							<SkeletonLoadingItem
								width="w-20"
								height="h-4"
								color="slate-300"
							/>
						</p>
					</div>
				</div>
				<p className="text-xs text-gray-500 flex items-center pt-4">
					<InformationCircleIcon className="size-4 mr-1.5" />
					Dit is overgenomen uit de patiëntenkaart, controleer de
					gegevens.
				</p>
			</div>
		);
	}

	if (error) {
		return (
			<div className="bg-orange-50 border-orange-100 border rounded-lg px-4 py-3 text-sm">
				<p className="text-orange-900">{error}</p>
			</div>
		);
	}

	return (
		<div className="mb-6">
			<div className="bg-slate-200 rounded-xl p-4 flex justify-between w-fit">
				<div className="pr-8">
					<p className="text-lg font-semibold">
						{values.firstName} {values.lastName}
					</p>
					<p className="text-sm text-gray-600">
						{values.patientnummer}
					</p>
					{phoneNumbers.length === 1 && (
						<p className="flex items-center pt-2">
							<PhoneIcon className="size-4 mr-2 text-slate-600" />
							<FormattedPhoneNumber
								phoneNumber={values.phoneNumber}
							/>
						</p>
					)}
				</div>
				<div>
					<Tooltip content="Gegevens wijzigen" id="edit-patient">
						<button onClick={() => setStep(1)}>
							<PencilSquareIcon className="size-4 mr-1 text-slate-500" />
						</button>
					</Tooltip>
				</div>
			</div>
			<p className="text-xs text-gray-500 flex items-center pt-4">
				<InformationCircleIcon className="size-4 mr-1.5" />
				Dit is overgenomen uit de patiëntenkaart, controleer de
				gegevens.
			</p>

			{phoneNumbers.length > 1 && (
				<div className="mt-4">
					<ChoosePhoneNumber
						phoneNumbers={phoneNumbers}
						onChange={(value) =>
							setFieldValue("phoneNumber", value)
						}
					/>
				</div>
			)}
		</div>
	);
};

export default PrefilledFromDesktopInfo;
