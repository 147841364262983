import React from "react";
import useFetch from "../../shared/hooks/useFetch";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import Tooltip from "../../shared/components/UIElements/Tooltip";
import { useTaskContext } from "../context/TaskContext";

const CheckTask = ({ task, isChecked, setTasks }) => {
	const updateTask = useFetch();
	const { triggerTaskUpdate } = useTaskContext();

	const toggleTaskStatus = async () => {
		const results = await updateTask(`/api/tasks/${task._id}`, {
			method: "PUT",
			body: JSON.stringify({
				isChecked: !isChecked,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		});
		setTasks((prevTasks) =>
			prevTasks.map((prevTask) =>
				prevTask._id === task._id ? results : prevTask
			)
		);
		console.log("Updated task:", results);

		triggerTaskUpdate();
	};

	return (
		<div onClick={toggleTaskStatus} className="cursor-pointer">
			{isChecked ? (
				<Tooltip
					content="Taak markeren als niet afgerond"
					id={`taak-${task._id}`}
				>
					<CheckCircleIcon className="h-10 w-10 text-green-500 -m-1" />
				</Tooltip>
			) : (
				<Tooltip content="Taak afvinken" id={`taak-${task._id}`}>
					<div className="h-8 w-8 rounded-full border-2 border-slate-300 flex items-center justify-center"></div>
				</Tooltip>
			)}
		</div>
	);
};

export default CheckTask;
