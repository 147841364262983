import React, { useEffect, useState } from "react";

import Modal from "../../../shared/components/UIElements/Modal";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Formik, Form } from "formik";

import Input from "../../../shared/components/FormElements/Input";
import Select from "../../../shared/components/FormElements/Select";
import Button from "../../../shared/components/FormElements/Button";
import BehandelaarAvatar from "./BehandelaarAvatar";
import useFetch from "../../../shared/hooks/useFetch";
import { toast } from "react-toastify";
import { CheckIcon } from "@heroicons/react/16/solid";
import CheckboxGroup from "../../../shared/components/FormElements/CheckboxGroup";
import TagInput from "../../../shared/components/FormElements/TagInput";

const EditBehandelaar = ({
	reload,
	firstName,
	lastName,
	functieID,
	treatmentTypes,
	behandelaarId,
	extraFuncties,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [occupations, setOccupations] = useState([]);
	const [treatments, setTreatments] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	const [initialValues, setInitialValues] = useState({
		firstName: firstName,
		lastName: lastName,
		functie: functieID,
		treatments: treatmentTypes,
		extraFuncties: [],
	});

	const updateBehandelaar = useFetch();

	const openModal = async () => {
		setIsLoading(true);
		await fetchData();
		setIsModalOpen(true);
		setIsLoading(false);
	};
	const closeModal = () => setIsModalOpen(false);

	const fetchData = async () => {
		const getOccupations = async () => {
			const response = await updateBehandelaar("/api/occupations", "GET");
			const formattedResponse = response.map((occupation) => ({
				label: occupation.title,
				value: occupation._id,
			}));
			setOccupations(formattedResponse);

			// Update initial values with the fetched occupations
			setInitialValues((prevValues) => ({
				...prevValues,
				extraFuncties: formattedResponse
					.filter((occupation) =>
						extraFuncties.includes(occupation.value)
					)
					.map((occupation) => ({
						value: occupation.value,
						label: occupation.label,
					})),
			}));
		};

		const getTreatments = async () => {
			const response = await updateBehandelaar("/api/treatments", "GET");
			const formattedResponse = response.map((treatment) => ({
				label: treatment.name,
				value: treatment._id,
			}));
			setTreatments(formattedResponse);
		};

		await Promise.all([getOccupations(), getTreatments()]);
	};

	const handleSubmit = async (values) => {
		await updateBehandelaar(`/api/behandelaars/${behandelaarId}`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ ...values, behandelaarId }),
		});
		closeModal();
		toast.success("Behandelaar gewijzigd", {
			position: "bottom-center",
			theme: "light",
			hideProgressBar: true,
			autoClose: 2000,
			icon: <CheckIcon className=" text-green-500" aria-hidden="true" />,
		});
		reload();
	};

	return (
		<>
			<button onClick={openModal}>
				<PencilSquareIcon className="h-5 w-5 inline" />
			</button>

			<Modal
				open={isModalOpen}
				setOpen={setIsModalOpen}
				includeCloseButton={false}
			>
				<>
					<div className="flex items-center px-6 pt-8">
						<BehandelaarAvatar
							firstName={firstName}
							lastName={lastName}
						/>
						<div className="pl-3">
							<h3 className="font-bold text-xl">
								Behandelaar bewerken
							</h3>
							<p className="text-gray-600 text-sm">
								{firstName + " " + lastName}
							</p>
						</div>
					</div>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						enableReinitialize
					>
						{({ values, handleChange }) => (
							<Form>
								<div className="px-6 pt-2 pb-8">
									<div className="flex gap-2">
										<Input
											label="Voornaam"
											name="firstName"
											value={values.firstName}
											onChange={handleChange}
											width="1/2"
											autoComplete="off"
											required
										/>
										<Input
											label="Achternaam"
											name="lastName"
											value={values.lastName}
											onChange={handleChange}
											width="1/2"
											autoComplete="off"
										/>
									</div>
									<Select
										label="Functie"
										name="functie"
										value={values.functie}
										onChange={handleChange}
										options={occupations}
										required
									/>

									<TagInput
										label="Kan ook behandelingen uitvoeren van de..."
										description="Bijvoorbeeld als de mondhygiënist ook behandelingen mag overnemen van de preventie-assistent"
										name="extraFuncties"
										suggestions={occupations.filter(
											(occupation) =>
												occupation.value !==
												values.functie
										)}
										selected={values.extraFuncties}
										width="full"
									/>
									{(values.functie ===
										"659fcad31bfb44314301ca81" ||
										(values.extraFuncties &&
											values.extraFuncties.some(
												(extraFunctie) =>
													extraFunctie.value ===
													"659fcad31bfb44314301ca81"
											))) && (
										<CheckboxGroup
											label="Behandelingen die de behandelaar kan uitvoeren"
											name="treatments"
											value={values.treatments}
											options={treatments}
											columns={2}
										/>
									)}
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
									<Button type="submit" size="medium">
										Updaten
									</Button>
									<Button
										size="medium"
										onClick={(e) => {
											e.preventDefault();
											setIsModalOpen(false);
										}}
										ghost
									>
										Annuleren
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</>
			</Modal>
		</>
	);
};

export default EditBehandelaar;
