import { UserProfile } from "@clerk/clerk-react";
import React from "react";
import Heading from "../../../shared/components/UIElements/Heading";

const ManageAccount = () => {
	return (
		<div className="mt-profile">
			<Heading size="2">Inloggegevens</Heading>
			<p className="text-gray-600 pt-2">
				Pas je inloggegevens, zoals je e-mail en wachtwoord aan.
			</p>

			<UserProfile
				localization={{
					userProfile: {
						start: {
							dangerSection: {
								deleteAccountTitle: "Verwijderen",
							},
						},
					},
				}}
				appearance={{
					elements: {
						card: {
							boxShadow: "none",
							marginLeft: "-25px",
							backgroundColor: "transparent",
						},
						profileSection__danger: "hidden",
						header: "hidden",
						profileSection__activeDevices: "hidden",
						// profileSectionPrimaryButton__emailAddresses:
						// 	"hidden",
						navbar: "hidden",
						formFieldInput:
							"rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6",
						formButtonPrimary:
							"bg-brand-600 hover:bg-brand-700 hover:scale-105 transition ease-in-out duration-300 rounded-lg normal-case text-base shadow-sm font-semibold tracking-normal py-3 text-white",
					},
				}}
			/>
		</div>
	);
};

export default ManageAccount;
