import React from "react";
import { Form, Formik } from "formik";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import useFetch from "../../shared/hooks/useFetch";
import Button from "../../shared/components/FormElements/Button";
import FormFields from "./FormFields";
import { validationSchema } from "./validationSchema";
import { handleSubmit } from "./submitHandler";

const NewSlotForm = ({ onSlotDetailsSubmit, initialFormValues }) => {
	const createSlot = useFetch();
	const { user } = useUser();

	return (
		<>
			<h3 className="text-lg leading-6 font-bold text-gray-900">
				Details van lege plek
			</h3>
			<Formik
				initialValues={initialFormValues}
				validationSchema={validationSchema}
				onSubmit={(values) =>
					handleSubmit(values, createSlot, user, onSlotDetailsSubmit)
				}
			>
				{({ errors, touched }) => (
					<Form>
						<FormFields errors={errors} touched={touched} />
						<Button type="submit" fullWidth classNames="mt-6">
							Volgende stap →
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default NewSlotForm;
