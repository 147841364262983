import React, { useState } from "react";
import NewPatientModal from "../pages/NewPatientModal";
import Button from "../../shared/components/FormElements/Button";
import { PlusIcon } from "@heroicons/react/16/solid";

const NewPatientButton = () => {
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setModalOpen(true)} iconPrefix={PlusIcon}>
				Nieuwe patiënt
			</Button>
			<NewPatientModal open={modalOpen} setOpen={setModalOpen} />
		</>
	);
};

export default NewPatientButton;
