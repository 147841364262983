import React from "react";
import BehandelaarAvatar from "./BehandelaarAvatar";
import DeleteBehandelaar from "./DeleteBehandelaar";
import EditBehandelaar from "./EditBehandelaar";

const BehandelaarItem = ({
	firstName,
	lastName,
	functie,
	extraFuncties = [],
	functieID,
	treatmentTypes,
	id,
	reload,
}) => {
	return (
		<tr key={id}>
			<td className="px-6 py-4 whitespace-nowrap">
				<div className="flex items-center">
					<div className="flex-shrink-0 h-10 w-10">
						<BehandelaarAvatar
							firstName={firstName}
							lastName={lastName}
						/>
					</div>
					<div className="ml-4">
						<div className="text-sm font-medium text-gray-900">
							{firstName + " " + lastName}
						</div>
					</div>
				</div>
			</td>
			<td className="pl-3 pr-4 py-4 whitespace-nowrap">
				<div className="text-sm text-gray-900">
					{functie}{" "}
					{extraFuncties.length > 0 && (
						<span className="text-xs text-gray-500">
							(+{extraFuncties.length} extra)
						</span>
					)}
				</div>
			</td>
			<td className="px-3 py-4 whitespace-nowrap">
				<p className="text-sm text-gray-600">
					{treatmentTypes.length > 0 &&
						`${treatmentTypes.length} / 15 behandelingen`}
				</p>
			</td>
			<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
				<a href="#" className="text-brand-600 hover:text-brand-900">
					<EditBehandelaar
						reload={reload}
						behandelaarId={id}
						firstName={firstName}
						lastName={lastName}
						treatmentTypes={treatmentTypes}
						extraFuncties={extraFuncties}
						functieID={functieID}
					/>
				</a>
				<a
					href="#"
					className="text-brand-600 hover:text-brand-900 pl-2"
				>
					<DeleteBehandelaar
						behandelaarId={id}
						name={firstName + " " + lastName}
						reload={reload}
					/>
				</a>
			</td>
		</tr>
	);
};

export default BehandelaarItem;
