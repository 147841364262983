import { InformationCircleIcon, MoonIcon } from "@heroicons/react/16/solid";
import React from "react";
import Tooltip from "../../shared/components/UIElements/Tooltip";

const SnoozeInfoBox = ({ snoozeUntil, forPatient = false }) => {
	const snoozeUntilDate = new Date(snoozeUntil);
	const snoozeUntilFormatted = snoozeUntilDate.toLocaleDateString("nl-NL", {
		month: "long",
		day: "numeric",
	});

	return (
		<div
			className="rounded-3xl flex justify-between p-6"
			style={{
				background:
					"linear-gradient(90deg, rgba(7,12,54,1) 0%, rgba(33,34,85,1) 100%)",
			}}
		>
			<div className="flex flex-col items-start">
				<MoonIcon className="size-5 text-white opacity-70 mb-2" />
				<p className="text-white font-semibold">
					{forPatient
						? "U bent met vakantie t/m "
						: "Patiënt is met vakantie t/m "}
					{snoozeUntilFormatted}
				</p>
				<p className="text-white text-sm opacity-70">
					We sturen geen uitnodigingen tot die tijd.
				</p>
			</div>

			{!forPatient && (
				<div>
					<div className="cursor-help">
						<Tooltip
							content="Dit heeft de patiënt zelf doorgegeven via WhatsApp"
							placement="top"
						>
							<InformationCircleIcon className="size-4 text-white opacity-30 mb-2" />
						</Tooltip>
					</div>
				</div>
			)}
		</div>
	);
};

export default SnoozeInfoBox;
