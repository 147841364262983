import React, { useEffect, useState } from "react";

import { ArrowLeftIcon, StarIcon } from "@heroicons/react/16/solid";
import { UserGroupIcon } from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useFetch from "../../shared/hooks/useFetch";
import Button from "../../shared/components/FormElements/Button";

import SkeletonLoading from "../../shared/components/UIElements/SkeletonLoading";
import EmptyState from "../../shared/components/Tables/EmptyState";
import BatchTimeline from "./BatchTimeline";
import { PaperAirplaneIcon } from "@heroicons/react/16/solid";

const NewSlotBatches = ({ slotDetails, onBack, slotID }) => {
	const fetchBatches = useFetch();
	const [batches, setBatches] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	const createSlot = useFetch();

	const sendMessages = async (slotID) => {
		await createSlot(`/api/slots/${slotID}/invite`, {
			method: "POST",
			body: JSON.stringify(batches),
			headers: {
				"Content-Type": "application/json",
			},
		});
		navigate(`/gaten/${slotID}`);
	};

	useEffect(() => {
		const getBatches = async () => {
			const response = await fetchBatches(`/api/slots/${slotID}/batches`);
			setBatches(response);
			setIsLoading(false);
		};

		getBatches();
	}, []);

	const createAndSendInvitations = async () => {
		try {
			const slotID = await createSlot("/api/slots", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(slotDetails),
			});
			await sendMessages(slotID);
			navigate(`/gaten/${slotID}`);
		} catch (error) {
			console.error(error);
			toast.error(
				"Er is iets misgegaan bij het versturen van de uitnodigingen"
			);
		}
	};
	return (
		<>
			<h3 className="text-lg leading-6 font-bold text-gray-900">
				Plan van aanpak: starten met het benaderen?
			</h3>
			<p className="mt-1 text-sm text-gray-500">
				We nodigen patiënten uit via WhatsApp in groepen. Hieronder zie
				je de groepen die we hebben samengesteld.
			</p>

			<div className="mt-6">
				{!isLoading && batches.length === 0 && (
					<EmptyState
						title="Geen patiënten die voldoen aan de criteria"
						description="Helaas... Geen patiënt komt in aanmerking voor dit agendagat."
						icon={UserGroupIcon}
					/>
				)}

				<div className="py-8">
					<BatchTimeline batches={batches} />
				</div>

				<div className="flex flex-col">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
							<div className="flex gap-2">
								<Button
									onClick={onBack}
									classNames="mt-4"
									ghost
									iconPrefix={ArrowLeftIcon}
								>
									Terug
								</Button>
								<Button
									onClick={createAndSendInvitations}
									classNames="mt-4"
									disabled={batches.length === 0}
									iconSuffix={PaperAirplaneIcon}
								>
									Uitnodigingen versturen
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewSlotBatches;
