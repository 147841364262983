import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "react-router-dom";

const MiniBackButton = ({ children, to = "/" }) => {
	return (
		<Link
			to={to}
			className="flex gap-1 items-center bg-slate-200 text-slate-700 rounded-full py-1 px-3 text-sm mb-4 hover:bg-slate-300 transition-all duration-200 w-fit"
		>
			<ArrowLeftIcon className="w-3.5 h-3.5" />
			{children || "Terug"}
		</Link>
	);
};

export default MiniBackButton;
