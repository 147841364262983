import React, { useState, useCallback } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/16/solid";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";

import Modal from "../components/UIElements/Modal";
import Button from "../components/FormElements/Button";

const useCustomConfirm = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [resolveReject, setResolveReject] = useState([]);

	const [settings, setSettings] = useState({});

	const confirm = useCallback((settings) => {
		setIsOpen(true);
		setSettings(settings);
		return new Promise((resolve, reject) => {
			setResolveReject([resolve, reject]);
		});
	}, []);

	const handleClose = useCallback(
		(result) => {
			setIsOpen(false);
			const [resolve] = resolveReject;
			resolve(result);
		},
		[resolveReject]
	);

	const ConfirmModal = useCallback(
		({ children }) => (
			<Modal
				open={isOpen}
				includeCloseButton={false}
				setOpen={() => handleClose(false)}
			>
				<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
					<div className="sm:flex sm:items-start">
						<div
							className={classNames(
								"mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
								{
									"bg-red-100": settings.warning,
									"bg-brand-100": !settings.warning,
								}
							)}
						>
							<ExclamationTriangleIcon
								className={classNames("h-6 w-6", {
									"text-red-600": settings.warning,
									"text-brand-600": !settings.warning,
								})}
								aria-hidden="true"
							/>
						</div>
						<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
							<Dialog.Title
								as="h3"
								className="text-base font-semibold leading-6 text-gray-900"
							>
								{settings.title || "Weet je het zeker?"}
							</Dialog.Title>
							<div className="mt-2">
								<p className="text-sm text-gray-500">
									{settings.description}
								</p>
							</div>
							<div>{children}</div>
						</div>
					</div>
				</div>
				<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2 space-y-2 sm:space-y-0">
					<Button
						size="medium"
						classNames={classNames(
							"w-full justify-center sm:w-fit",
							{
								"bg-red-600 hover:bg-red-500": settings.warning,
								"bg-brand-600 hover:bg-brand-500":
									!settings.warning,
							}
						)}
						onClick={() => {
							handleClose(true);
						}}
					>
						{settings.cta || "Ja, ik weet het zeker"}
					</Button>
					<Button
						size="medium"
						classNames="w-full justify-center sm:w-fit"
						ghost
						onClick={() => handleClose(false)}
					>
						{settings.cancel || "Annuleren"}
					</Button>
				</div>
			</Modal>
		),
		[isOpen, settings, handleClose]
	);

	return [confirm, ConfirmModal];
};

export default useCustomConfirm;
