import React, { useCallback, useState } from "react";
import { useField, useFormikContext } from "formik";
import { ReactTags } from "react-tag-autocomplete";
import Label from "./Label";

export default function TagInput({
	label,
	description,
	suggestions,
	...props
}) {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(props);
	const [selected, setSelected] = useState(field.value || []);

	const onAdd = useCallback(
		(newTag) => {
			const updatedTags = [...selected, newTag];
			setSelected(updatedTags);
			setFieldValue(field.name, updatedTags);
		},
		[selected, setFieldValue, field.name]
	);

	const onDelete = useCallback(
		(tagIndex) => {
			const updatedTags = selected.filter((_, i) => i !== tagIndex);
			setSelected(updatedTags);
			setFieldValue(field.name, updatedTags);
		},
		[selected, setFieldValue, field.name]
	);

	return (
		<div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
			<div className="col-span-full">
				<Label label={label} description={description} />
				<div className="mt-2 relative">
					<ReactTags
						labelText="Selecteer functie"
						placeholder="Selecteer functie"
						noOptionsText="Geen overeenkomende opties gevonden"
						selected={selected}
						suggestions={suggestions}
						onAdd={onAdd}
						onDelete={onDelete}
						classNames={{
							root: "react-tags shadow-sm border border-gray-300 rounded-md text-sm",
							rootIsActive: "is-active",
							rootIsDisabled: "is-disabled",
							rootIsInvalid: "is-invalid",
							label: "react-tags__label",
							tagList: "react-tags__list",
							tagListItem: "react-tags__list-item",
							tag: "react-tags__tag",
							tagName: "react-tags__tag-name",
							comboBox: "react-tags__combobox",
							input: "react-tags__combobox-input",
							listBox: "react-tags__listbox z-99 absolute",
							option: "react-tags__listbox-option",
							optionIsActive: "is-active",
							highlight: "react-tags__listbox-option-highlight",
						}}
						allowBackspace
					/>
					{meta.touched && meta.error ? (
						<div className="text-red-600 text-sm pt-2">
							{meta.error}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}
