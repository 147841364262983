import React, { useEffect, useState } from "react";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import NewSlotForm from "../components/NewSlotForm";
import SlotSteps from "../components/SlotSteps";
import NewSlotBatches from "../components/NewSlotBatches";
import NewSlotPatients from "../components/NewSlotPatients";

const NewSlotPage = () => {
	const [slotDetails, setSlotDetails] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);
	const [slotID, setSlotID] = useState(null);
	const [formValues, setFormValues] = useState({
		date: "",
		startTime: "",
		endTime: "",
		behandelaar: "",
	});
	const steps = [
		{
			name: "Details",
			status:
				currentStep === 0
					? "current"
					: currentStep > 0
					? "complete"
					: "upcoming",
		},
		{
			name: "Kies patiënten",
			status:
				currentStep === 1
					? "current"
					: currentStep > 1
					? "complete"
					: "upcoming",
		},
		{
			name: "Preview",
			status:
				currentStep === 2
					? "current"
					: currentStep > 2
					? "complete"
					: "upcoming",
		},
	];

	const handleSlotDetailsSubmit = (details) => {
		setSlotDetails(details);
		setFormValues(details);
		setSlotID(details.slotID);
		setCurrentStep(1);
	};

	const handleChosenPatients = () => {
		setCurrentStep(2);
	};

	const handleBack = () => {
		setCurrentStep(currentStep - 1);
	};

	return (
		<Container>
			<div>
				<Heading>Meld een nieuwe lege plek in de agenda</Heading>
				<p className="pt-2 text-gray-600">
					Wij analyseren de wachtlijst voor je om te kijken welke
					patiënten in aanmerking komen.
				</p>
			</div>
			<div className="mt-12 flex">
				<div className="w-1/5">
					<SlotSteps
						steps={steps}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
					/>
				</div>
				<div className="w-4/5">
					<div className="overflow-hidden rounded-lg bg-white shadow">
						<div className="px-4 py-5 sm:p-6">
							{currentStep === 0 && (
								<NewSlotForm
									onSlotDetailsSubmit={
										handleSlotDetailsSubmit
									}
									initialFormValues={formValues}
								/>
							)}
							{currentStep === 1 && (
								<NewSlotPatients
									onChosenPatients={handleChosenPatients}
									slotID={slotID}
									onBack={handleBack}
								/>
							)}
							{currentStep === 2 && (
								<NewSlotBatches
									slotDetails={slotDetails}
									slotID={slotID}
									onBack={handleBack}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default NewSlotPage;
