import React from "react";

const Badge = ({ children, ...props }) => {
	return (
		<span className=" bg-green-100 text-green-700 rounded-full py-0.5 px-2 text-xs w-fit">
			{children}
		</span>
	);
};

export default Badge;
