import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import useFetch from "../../shared/hooks/useFetch";

import SidePanel from "../../shared/components/UIElements/SidePanel";
import Heading from "../../shared/components/UIElements/Heading";
import NewPatientForm from "../components/NewPatientForm";

const EditPatientPage = ({ open, setOpen }) => {
	const [patient, setPatient] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const { patientId } = useParams();

	const fetchPatient = useFetch();

	useEffect(() => {
		if (open) {
			const getPatient = async () => {
				const response = await fetchPatient(
					`/api/patients/${patientId}`
				);
				setPatient({
					id: response._id,
					firstName: response.firstName,
					lastName: response.lastName,
					phoneNumber:
						response.phoneNumber || response.phoneNumbers[0],
					phoneNumber2: response.phoneNumbers[1],
					phoneNumber3: response.phoneNumbers[2],
					unformattedDate: response.appointment.datePlanned
						? dayjs(response.appointment.datePlanned)
						: null,
					otherBehandelaars: response.appointment.behandelaarType
						? true
						: false,
					treatment:
						response.appointment.treatmentType &&
						response.appointment.treatmentType._id,
					// datePlanned: response.appointment.datePlanned,
					notes: response.notes,
					priority: response.priority,
					unformattedInviteFrom: response.inviteFrom
						? dayjs(response.inviteFrom)
						: null,
					// inviteFrom: response.inviteFrom,
					behandelaar: response.behandelaar._id,
					patientnummer: response.patientNumber,
					behandelingDuur: +response.appointment.duration,
				});
				// window.confirm(JSON.stringify(patient));
				setIsLoading(false);
			};
			getPatient();
		}
	}, [open]);

	if (!isLoading)
		return (
			<SidePanel open={open} setOpen={setOpen} blank>
				<div className="flex flex-col px-6 pt-14">
					<div className="flex items-center justify-between">
						<h2 className="text-2xl font-bold leading-6 text-black pb-2">
							Patiënt bewerken
						</h2>
					</div>
					<div className="mt-1">
						<p className="text-sm text-slate-500">
							De patiënt wordt niet op de hoogte gebracht van
							wijzigingen.
						</p>
					</div>
				</div>
				<div className="">
					<NewPatientForm
						isEditing={true}
						initialPatient={patient}
						setOpen={setOpen}
					/>
				</div>
			</SidePanel>
		);
};

export default EditPatientPage;
