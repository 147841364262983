import React from "react";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";

import TasksList from "../components/TasksList";
import TasksCounter from "../components/TasksCounter";

const AllTasksPage = () => {
	return (
		<Container>
			<div className="flex gap-2 items-end">
				<Heading>Alle taken</Heading>
				<div className="mb-0.5">
					<TasksCounter />
				</div>
			</div>
			<p className="text-gray-600 pb-6 pt-2 mb-4">
				Verzet de afspraken van patiënten die een lege plek hebben
				bevestigd.
			</p>
			<TasksList />
		</Container>
	);
};

export default AllTasksPage;
