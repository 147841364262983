import React from "react";
import Container from "../components/UIElements/Container";
import Heading from "../components/UIElements/Heading";
import {
	ChatBubbleOvalLeftEllipsisIcon,
	LifebuoyIcon,
	UsersIcon,
	VideoCameraIcon,
} from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";
import Badge from "../components/UIElements/Badge";
import { Link } from "react-router-dom";

const GetHelp = () => {
	return (
		<Container>
			<Heading>Heb je hulp nodig?</Heading>
			<p className="text-gray-500 mt-3 mb-10">
				We hebben een uitgebreid helpcentrum voor je klaarstaan, en
				anders kun je ook contact met ons opnemen via de chat
			</p>

			<div className="flex gap-6 [&>div]:w-1/3 [&>div]:rounded-3xl [&>div]:p-6 [&>div]:bg-[#eff2f8] [&>div]:cursor-pointer">
				<div className="hover:opacity-90 transition-all duration-300 hover:scale-105">
					<Link
						to="https://help.dentiva.nl/nl/collections/8928551-snelterecht"
						target="_blank"
					>
						<div className="flex items-top justify-between">
							<LifebuoyIcon className="size-8 mb-3 text-brand-600" />
							<ArrowTopRightOnSquareIcon className="size-5 text-slate-400" />
						</div>
						<div className="flex items-center gap-2">
							<h3 className="text-lg font-semibold">
								Helpcentrum
							</h3>
							<Badge>Meteen antwoord</Badge>
						</div>
						<p className="text-gray-500 mt-1">
							Uitgebreide uitleg met antwoorden op de meest
							gestelde vragen
						</p>
					</Link>
				</div>
				<div
					className="hover:opacity-90 transition-all duration-300 hover:scale-105"
					onClick={() => window.Intercom("showNewMessage")}
				>
					<ChatBubbleOvalLeftEllipsisIcon className="size-8 mb-3 text-brand-600" />

					<h3 className="text-lg font-semibold">Chat</h3>

					<p className="text-gray-500 mt-1">
						Neem contact op met het team via de chat rechtsonder in
						beeld
					</p>
				</div>
				<div className="hover:opacity-90 transition-all duration-300 hover:scale-105">
					<Link
						to="https://calendly.com/matthijs-b658/30min"
						target="_blank"
					>
						<div className="flex items-top justify-between">
							<VideoCameraIcon className="size-8 mb-3 text-brand-600" />
							<ArrowTopRightOnSquareIcon className="size-5 text-slate-400" />
						</div>
						<h3 className="text-lg font-semibold">
							Kwartier hulp via Zoom
						</h3>
						<p className="text-gray-500 mt-1">
							Plan een 1-op-1 Zoom sessie met Matthijs
						</p>
					</Link>
				</div>
			</div>
			<div className="mt-16 pt-14 border-t border-gray-200 flex">
				<div className="w-1/2">
					<div className="flex items-center">
						<UsersIcon className="size-6 mr-2 text-brand-600" />
						<h3 className="text-xl font-bold">Voor patiënten</h3>
					</div>
					<p className="text-slate-500 mt-1">
						Hulp bij het informeren van je patiënten.
					</p>
					<ul className="[&>li]:border-b [&>li]:border-slate-3000 [&>li]:w-fit [&>li]:duration-200 [&>li]:ease-in-out text-black font-medium space-y-3.5 mt-6 ">
						<li className="hover:text-brand-600 hover:border-brand-300">
							<Link to="/help/patienten/site">
								Website met informatie voor patiënten
							</Link>
						</li>
						<li className="hover:text-brand-600 hover:border-brand-300">
							<Link to="/help/patienten/email">
								Informatiemail naar huidige snel bellen lijst
							</Link>
						</li>
						<li className="hover:text-brand-600 hover:border-brand-300">
							<Link to="/help/patienten/tekst">
								Tekst voor onderaan de afspraakbevestiging
							</Link>
						</li>
					</ul>
				</div>
				{/* <div className="w-1/2">
					<Heading size="3">Voor behandelaren</Heading>
					<div></div>
				</div> */}
			</div>
		</Container>
	);
};

export default GetHelp;
