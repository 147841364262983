import React, { useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import PatientsTable from "../components/PatientsTable";

import NewPatientButton from "../components/NewPatientButton";
import Button from "../../shared/components/FormElements/Button";
import NewPatientModal from "./NewPatientModal";
import PatientStats from "../components/PatientStats";
import { ChartBarIcon } from "@heroicons/react/20/solid";

const AllPatients = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState(
		searchParams.get("fromApp") === "true"
	);

	const handleCloseModal = () => {
		setIsOpen(false);
		setSearchParams({});
		navigate("/patienten/all");
	};

	return (
		<>
			<Container>
				<div className="flex justify-between mb-10">
					<div>
						<Heading className="mt-0">Patiënten</Heading>
						{/* <p className="text-gray-600 pt-2">
							Beheer de Snelterecht lijst van je praktijk
						</p> */}
					</div>
					<div className="flex items-center justify-center gap-3">
						<Button
							ghost
							to="/patienten/importeren"
							iconPrefix={ArrowDownTrayIcon}
						>
							Importeren
						</Button>
						<NewPatientButton />
					</div>
				</div>
				<div className="mt-3 mb-10">
					<div className="flex gap-2 items-center mb-3">
						<ChartBarIcon className="size-4 text-slate-400" />
						<h3 className="text-sm font-semibold text-slate-500 uppercase">
							Afgelopen 7 dagen
						</h3>
					</div>
					<PatientStats />
				</div>
				<PatientsTable />

				<NewPatientModal
					fromDesktopApp={isOpen}
					open={isOpen}
					setOpen={handleCloseModal}
					reload={() => {}}
				/>
			</Container>
		</>
	);
};

export default AllPatients;
