import { useState } from "react";
import Heading from "../../shared/components/UIElements/Heading";
import Container from "../../shared/components/UIElements/Container";
import Button from "../../shared/components/FormElements/Button";
import ImportPatientsWizard from "../components/ImportPatientsWizard";

import {
	ArrowDownTrayIcon,
	ArrowLeftIcon,
	SparklesIcon,
	UserPlusIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";

const ImportPatientsPage = () => {
	const [patients, setPatients] = useState([]);
	const [erroredPatients, setErroredPatients] = useState([]);
	const [wizardIsOpen, setWizardIsOpen] = useState(false);

	if (patients.length > 0) {
		return (
			<>
				<Container>
					<MiniBackButton to="/patienten/all">
						Terug naar overzicht
					</MiniBackButton>

					<Heading>
						Gelukt! Je hebt {patients.length} van de{" "}
						{patients.length + erroredPatients.length} patiënten
						geïmporteerd.
					</Heading>
					<p className="text-gray-500 mt-2">
						Check of alle gegevens kloppen en of alle patiënten ook
						daadwerkelijk zijn geïmporteerd. Zijn er een aantal
						patiënten niet geïmporteerd? Voeg de rest handmatig toe.
					</p>

					{erroredPatients.length > 0 && (
						<>
							<h2 className="text-red-500">
								Er zijn {erroredPatients.length} patiënten niet
								geïmporteerd. Voeg deze handmatig toe.
							</h2>
						</>
					)}
				</Container>
			</>
		);
	}

	return (
		<>
			<Container>
				<MiniBackButton to="/patienten/all">
					Terug naar overzicht
				</MiniBackButton>

				<Heading>
					Importeer patiënten van je bestaande snel bellen lijst
				</Heading>
				<p className="text-gray-500 mt-2">
					Hou je met de praktijk al een snel bellen lijst bij?
					Importeer deze dan in Snelterecht.
				</p>

				<div className="my-10 bg-white border border-slate-300 p-6 rounded-xl shadow-sm">
					<h2 className="text-lg font-bold">
						Voordat je begint... Zorg ervoor dat je alle
						behandelaren hebt toegevoegd.
					</h2>
					<p className="text-gray-500 mb-4">
						Pas dan kun je beginnen met het importeren van de
						patiënten.
					</p>
					<div className="w-fit">
						<Button
							ghost
							to="/instellingen/#behandelaars"
							iconPrefix={UserPlusIcon}
						>
							Behandelaren beheren
						</Button>
					</div>
				</div>

				<div className="space-y-12 mt-10">
					<div>
						<h2 className="text-lg font-bold">
							#1 - Download ons voorbeeld Excel bestand en plak
							hier jullie lijst in
						</h2>
						<p className="text-gray-500 mb-4">
							Vul de lijst aan met de patiënten die je wilt
							importeren. Let er op dat de datum in het format
							JJJJ-MM-DD staat.
						</p>
						<div className="w-fit">
							<Button
								href="https://www.dropbox.com/scl/fi/eztleowl16l2wkxlki524/Snelterecht-Importeer-pati-nten.xlsx?rlkey=fx69t36p4fsncczv0sn72xfdg&dl=1"
								target="_blank"
								iconPrefix={ArrowDownTrayIcon}
							>
								Download Excel bestand
							</Button>
						</div>
					</div>
					<div>
						<h2 className="text-lg font-bold">
							#2 - Importeer patiënten
						</h2>
						<p className="text-gray-500 mb-4">
							Vergeet niet de behandelaren en het type behandelaar
							te 'matchen' bij de tweede stap. Zodra een patiënt
							is geïmporteerd sturen we een melding per WhatsApp
							die aangeeft dat ze aan jullie wachtlijst zijn
							toegevoegd.
						</p>
						<Button
							onClick={() => setWizardIsOpen(true)}
							iconPrefix={SparklesIcon}
						>
							Start met importeren
						</Button>
					</div>
				</div>
			</Container>
			<ImportPatientsWizard
				isOpen={wizardIsOpen}
				onClose={() => setWizardIsOpen(false)}
				setPatients={setPatients}
				setErroredPatients={setErroredPatients}
			/>
		</>
	);
};

export default ImportPatientsPage;
