import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../shared/components/FormElements/Button";
import {
	CalendarDaysIcon,
	ClockIcon,
	UserIcon,
} from "@heroicons/react/16/solid";
import FormattedDate from "../../shared/components/util/FormattedDate";
import useCustomConfirm from "../../shared/hooks/useCustomConfirm";
import Loading from "../../shared/pages/Loading";

const ClaimSlotPage = () => {
	const [patient, setPatient] = useState();
	const [slot, setSlot] = useState();
	const [practice, setPractice] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const [confirm, ConfirmModal] = useCustomConfirm();

	const { slotId, patientId } = useParams();

	const navigate = useNavigate();

	const claimSlot = async () => {
		const result = await confirm({
			title: "Weet je zeker dat je er bent?",
			description:
				"Als je op ja klikt, wordt de plek in de agenda definitief voor jou gereserveerd.",
			cta: "Ja, ik ben er",
		});
		if (result) {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/public/slot/${slotId}/claim`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ patientId }),
				}
			);

			console.log(response);

			if (response.ok) {
				navigate(`/c/bevestiging/${slotId}?pt=${patientId}`);
			} else {
				navigate(`/c/claimed`);
			}
		}
	};

	useEffect(() => {
		const fetchSlot = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/public/slot/${slotId}`
			);
			const responseData = await response.json();
			if (
				responseData.slot.status === "claimed" &&
				responseData.slot.claimedBy !== patientId
			) {
				navigate(`/c/claimed`);
			} else {
				setSlot(responseData.slot);
				setPractice(responseData.practice);
			}
		};
		const fetchPatient = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/public/patient/${patientId}`
			);
			const responseData = await response.json();
			setPatient(responseData);
		};
		fetchSlot();
		fetchPatient();
		setIsLoading(false);
	}, []);

	if (isLoading || !slot || !patient || !practice) return <Loading />;

	if (slot.status === "claimed" && slot.claimedBy === patientId) {
		return (
			<>
				<div className="flex flex-col h-[100dvh] justify-between px-6 pt-24 pb-6">
					<div>
						<h1 className="text-3xl font-bold">
							Je hebt deze plek al bevestigd
						</h1>
						<p className="text-gray-600 pt-3">
							We zien je in de praktijk!
						</p>
					</div>
				</div>
			</>
		);
	}

	if (new Date(slot.date) < new Date()) {
		return (
			<>
				<div className="flex flex-col h-[100dvh] justify-between px-6 pt-24 pb-6">
					<div>
						<h1 className="text-3xl font-bold">
							Helaas, deze vrijgekomen plek is al geweest
						</h1>
						<p className="text-gray-600 pt-3">
							De vrijgekomen plek was op{" "}
							<FormattedDate date={slot.date} />.
						</p>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="flex flex-col h-[100dvh] justify-between px-6 pt-24 pb-6">
				<div>
					<h1 className="text-3xl font-bold">Bevestig uw plek</h1>
					<p className="text-gray-600 pt-3">
						Goed nieuws! Er is een nieuwe plek in de agenda
						vrijgekomen. Kijk goed of u op onderstaande datum en
						tijd bij de tandartspraktijk kunt zijn.
					</p>
					<div className="text-gray-600 pt-6">
						<p className=" flex items-center pb-2">
							<UserIcon className="h-5 w-5 text-brand-500 mr-3" />
							<p>
								{slot.behandelaar.firstName}
								{slot.behandelaar.lastName &&
									` ${slot.behandelaar.lastName}`}{" "}
								({slot.behandelaar.occupation.title})
							</p>
						</p>
						<p className="flex items-center pb-1.5">
							<CalendarDaysIcon className="h-5 w-5 text-brand-500 mr-3" />
							<FormattedDate date={slot.date} />
						</p>
						<p className="flex items-center pb-1.5">
							<ClockIcon className="h-5 w-5 text-brand-500 mr-3" />
							<p>
								{slot.startTime} - {slot.endTime} uur
							</p>
						</p>
					</div>
					<div className="border-slate-300 border bg-slate-50 rounded-2xl px-6 py-4 mt-8 mb-16">
						<h3 className="font-semibold text-md mb-1">
							{practice.name}
						</h3>
						<p className="text-gray-500 flex items-center pb-1">
							{practice.address.street}
						</p>
						<p className="text-gray-500 flex items-center pb-2">
							{practice.address.postalCode}{" "}
							{practice.address.city}
						</p>
					</div>
				</div>
				<div>
					<Button onClick={claimSlot} fullWidth>
						Ja, bevestig de plek
					</Button>
				</div>
			</div>
			<ConfirmModal />
		</>
	);
};

export default ClaimSlotPage;
