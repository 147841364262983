import React, { useState, useEffect } from "react";

import { useUser } from "@clerk/clerk-react";

import { ProgressCircle } from "@tremor/react";
import { ClockIcon, UsersIcon } from "@heroicons/react/20/solid";

import Container from "../components/UIElements/Container";
import Heading from "../components/UIElements/Heading";
import useFetch from "../hooks/useFetch";
import TasksList from "../../tasks/components/TasksList";
import TasksCounter from "../../tasks/components/TasksCounter";
import MiniSlotItem from "../../slots/components/MiniSlotItem";
import GettingStarted from "../../practice/pages/GettingStarted";
import TasksListItem from "../../tasks/components/TasksListItem";

const Dashboard = () => {
	const { user } = useUser();
	const [stats, setStats] = useState({});
	const [slots, setSlots] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const fetchSlotsFilled = useFetch();

	useEffect(() => {
		const getNotifications = async () => {
			const response = await fetchSlotsFilled(
				`/api/practice/user/${user.id}/stats`
			);
			setStats(response);

			const slotsRes = await fetchSlotsFilled(
				`/api/slots/user/${user.id}/`
			);
			setSlots(slotsRes);
			setIsLoading(false);
		};

		getNotifications();
	}, []);

	if (user.unsafeMetadata.hasCompletedOnboarding !== true) {
		return <GettingStarted />;
	}

	return (
		<Container>
			<>
				{stats.totalSlotsFilled === 0 ? (
					<Heading>👋 Welkom bij Snelterecht!</Heading>
				) : (
					<Heading>
						Je hebt met Snelterecht{" "}
						{stats.totalSlotsFilled === 1 ? (
							<span
								id="gradientText-nah"
								className="text-brand-intense"
							>
								<br />
								je eerste lege plek in de agenda
							</span>
						) : (
							<>
								in totaal
								<br />
								<span id="gradientText">
									{isLoading ? (
										<div className="animate-pulse inline-block w-10 h-8 bg-gray-300 rounded-xl -mb-2"></div>
									) : (
										`${stats.totalSlotsFilled}`
									)}{" "}
									lege plekken in de agenda
								</span>
							</>
						)}{" "}
						gevuld, goed bezig!
					</Heading>
				)}
			</>
			{(isLoading || stats.totalSlotsFilled > -1) && (
				<div className="bg-[#e8eff6] rounded-3xl mt-12 flex ">
					<div className="w-1/3 py-8 px-8 border-r-2 border-[#d7e2ed]">
						{isLoading ? (
							<div className="animate-pulse space-y-3">
								<div className="h-8 bg-slate-400 rounded w-1/3"></div>
								<div className="h-4 bg-slate-300 rounded w-2/5"></div>
							</div>
						) : (
							<div className="flex items-center gap-4">
								<div className="size-16 text-brand-600 bg-[#F8FAFC] rounded-full p-3">
									<UsersIcon className="" />
								</div>
								<div>
									<p className="font-bold text-3xl">
										{stats.patientsOnWaitlist}
										<span className="text-slate-600 text-sm font-semibold uppercase ml-1.5">
											patiënten
										</span>
									</p>

									<p className="text-slate-500">
										op je Snelterecht lijst
									</p>
								</div>
							</div>
						)}
					</div>
					<div className="w-1/3 py-8 px-8 border-r-2 border-[#d7e2ed]">
						{isLoading ? (
							<div className="animate-pulse space-y-3">
								<div className="h-8 bg-slate-400 rounded w-1/3"></div>
								<div className="h-4 bg-slate-300 rounded w-4/5"></div>
							</div>
						) : (
							stats.averageTimeToFillSlot && (
								<div className="flex items-center gap-4">
									<div className="size-16 text-brand-600 bg-[#F8FAFC] rounded-full p-3">
										<ClockIcon className="" />
									</div>
									<div>
										<p className="font-bold text-3xl">
											{stats.averageTimeToFillSlot.value}
											<span className="text-slate-600 text-sm font-semibold uppercase ml-1.5">
												{
													stats.averageTimeToFillSlot
														.units
												}
											</span>
										</p>

										<p className="text-slate-500">
											om een plek op te vullen
										</p>
									</div>
								</div>
							)
						)}
					</div>
					<div className="w-1/3 py-8 px-8">
						{isLoading ? (
							<div className="animate-pulse flex items-center gap-5">
								<div className="w-12 h-12 bg-slate-400 rounded-full"></div>
								<div>
									<div className="h-8 bg-slate-400 rounded mb-2"></div>
									<div className="h-4 bg-slate-300 rounded w-32"></div>
								</div>
							</div>
						) : (
							<div className="flex items-center gap-4">
								<ProgressCircle
									value={stats.percentageSlotsFilled}
									size="200px"
									radius={27}
									strokeWidth={8}
									color="green"
								/>
								<div>
									<p className="font-bold text-3xl">
										{stats.percentageSlotsFilled}
										<span className="font-normal">%</span>
									</p>
									<p className="text-slate-500">
										van je lege plekken gevuld
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			<div className="flex pt-10 gap-10">
				<div className="w-1/2">
					<div className="flex pb-4 gap-2 items-end">
						<Heading size="2">Taken</Heading>
						<TasksCounter />
					</div>
					<TasksList />
				</div>

				{(slots.length > 0 || isLoading) && (
					<div className="w-1/2">
						<Heading size="2">Lege plekken</Heading>

						<div className="mt-4 space-y-4">
							{isLoading
								? Array(3)
										.fill()
										.map((_, index) => (
											<div
												key={index}
												className="flex items-center animate-pulse"
											>
												<div className="flex-1">
													<div className="h-16 bg-gray-200 rounded-xl"></div>
												</div>
											</div>
										))
								: slots.map((slot) => {
										return (
											<MiniSlotItem
												key={slot._id}
												id={slot._id}
												{...slot}
											/>
										);
								  })}
						</div>
					</div>
				)}
			</div>
		</Container>
	);
};

export default Dashboard;
