import React from "react";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";

const PaymentSucceeded = () => {
	return (
		<Container>
			<Heading>
				Gelukt! Je bent klaar om op de automatische piloot lege plekken
				in de agenda te vullen.
			</Heading>
			<p className="text-gray-500 mt-4">
				Je krijgt binnen enkele minuten per mail een uitnodiging om je
				wachtwoord in te stellen.
			</p>
		</Container>
	);
};

export default PaymentSucceeded;
