import React, { useState } from "react";

import Modal from "../../../shared/components/UIElements/Modal";

import Button from "../../../shared/components/FormElements/Button";
import NewBehandelaarForm from "./NewBehandelaarForm";
import { PlusIcon } from "@heroicons/react/16/solid";

const NewBehandelaar = ({ reload }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	return (
		<>
			<Button onClick={openModal} iconPrefix={PlusIcon}>
				Behandelaar toevoegen
			</Button>
			<Modal open={isModalOpen} setOpen={setIsModalOpen}>
				<h3 className="px-8 font-bold text-xl mb-2 -mt-4">
					Nieuwe behandelaar
				</h3>
				<div>
					<NewBehandelaarForm
						closeModal={closeModal}
						reload={reload}
					/>
				</div>
			</Modal>
		</>
	);
};

export default NewBehandelaar;
