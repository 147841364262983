import React, { useState } from "react";

import {
	CalendarIcon,
	ChatBubbleOvalLeftIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/16/solid";

import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import Video from "../../shared/components/UIElements/Video";
import OnboardingChecklist from "../../users/components/onboarding/OnboardingChecklist";
import Button from "../../shared/components/FormElements/Button";
import NewPatientButton from "../../patients/components/NewPatientButton";

const QuestionsButton = () => {
	return (
		<button
			className="text-sm pt-3 opacity-70 hover:opacity-50 transition-all duration-200 flex items-center"
			onClick={() => window.Intercom("showNewMessage")}
		>
			<ChatBubbleOvalLeftIcon className="h-4 w-4 mr-1" />
			Vragen? Open de chat.
		</button>
	);
};

const GettingStarted = () => {
	const { user } = useUser();
	const [allCompleted, setAllCompleted] = useState(false);
	const navigate = useNavigate();

	const completeChecklist = (isCompleted) => {
		setAllCompleted(isCompleted);
		user.update({
			unsafeMetadata: {
				...user.unsafeMetadata,
				hasCompletedChecklist: isCompleted,
			},
		});
	};

	const completeOnboarding = () => {
		user.update({
			unsafeMetadata: {
				...user.unsafeMetadata,
				hasCompletedOnboarding: true,
			},
		});

		navigate("/");
	};

	return (
		<Container>
			<div className="flex items-center gap-x-16">
				<div className="w-3/5">
					<Video
						videoID="946115988"
						title="Starten met Snelterecht"
					/>
				</div>
				<div className="w-2/5">
					<Heading>Welkom bij Snelterecht 👋</Heading>
					<p className="text-gray-600 pt-4">
						Binnen no-time zijn jullie klaar om lege plekken in de
						agenda op te vullen, zonder dat je daarvoor eindeloos
						hoeft rond te bellen!
					</p>
				</div>
			</div>
			<div className="py-6 px-6 mt-10 bg-slate-200 rounded-2xl shadow-slate-100">
				<OnboardingChecklist
					setAllCompleted={completeChecklist}
					// isCompleted={user.unsafeMetadata.hasCompletedChecklist}
				/>
			</div>
			<div className="flex pt-24 gap-x-16 items-center">
				<div className="w-2/5">
					<h3 className="text-2xl font-bold">
						Zo voeg je een nieuwe patiënt toe aan Snelterecht
					</h3>
					<p className="text-gray-600 pt-4 pb-6">
						Je snel bellen lijst houd je bij in Snelterecht. Het
						toevoegen van een nieuwe patiënt kost in de praktijk
						minder dan een minuut!
						<br />
						<br />
						Voeg jezelf eens toe als patiënt, dan zie je de
						berichten die de patiënt ook binnenkrijgt.
					</p>
					<NewPatientButton />
					<QuestionsButton />
				</div>
				<div className="w-3/5">
					<Video
						videoID="945764401"
						title="Patiënten toevoegen aan de wachtlijst"
					/>
				</div>
			</div>
			<div className="flex py-24 gap-x-16 items-center">
				<div className="w-3/5">
					<Video
						videoID="945770816"
						title="Nieuwe lege plek in de agenda melden"
					/>
				</div>
				<div className="w-2/5">
					<h3 className="text-2xl font-bold">
						Belt er een patiënt af? Zo meld je een nieuwe lege plek
						in de agenda.
					</h3>
					<p className="text-gray-600 pt-4 pb-6">
						Wij analyseren je wachtlijst, kijken wie er in
						aanmerking komt voor de lege plek, en verzenden de
						uitnodigingen per WhatsApp.
						<br />
						<br />
						Heb je jezelf net toegevoegd als patiënt? Meld dan nu
						een lege plek, zodat je ziet hoe het werkt.
					</p>
					<Button
						to="/gaten/nieuw"
						iconPrefix={CalendarIcon}
						target="_blank"
					>
						Nieuwe lege plek melden
					</Button>
					<QuestionsButton />
				</div>
			</div>
			{allCompleted && (
				<div className="px-8 py-8 mt-10 bg-slate-200 rounded-3xl text-left flex items-center gap-4 justify-between sticky bottom-10 shadow-slate-100 shadow-2xl">
					<div>
						<h3 className="text-xl font-bold">
							Alles begrepen? Markeer de onboarding als afgerond
						</h3>
						<p className="text-gray-600 pt-1">
							Je kunt de filmpjes altijd nog terugkijken in ons
							helpcentrum.
						</p>
					</div>

					<div className="">
						<Button
							ghost
							onClick={completeOnboarding}
							iconPrefix={CheckCircleIcon}
							classNames="text-green-600"
						>
							Markeer als afgerond
						</Button>
					</div>
				</div>
			)}
		</Container>
	);
};

export default GettingStarted;
