import React from "react";
import { useField } from "formik";
import { ArrowRightIcon } from "@heroicons/react/16/solid";
import Label from "./Label";

const TimeRangePicker = ({ label, description, ...props }) => {
	const [field, meta, helpers] = useField(props);

	const handleChange = (e) => {
		const { name, value } = e.target;
		const [startTime, endTime] = field.value || [];
		const newValue =
			name === "startTime" ? [value, endTime] : [startTime, value];
		helpers.setValue(newValue);
	};

	return (
		<div
			className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}
		>
			<div className="col-span-full">
				<Label label={label} description={description} />
				<div className="flex space-x-4 items-center">
					<input
						type="time"
						name="startTime"
						value={field.value?.[0] || ""}
						onChange={handleChange}
						className="block w-1/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						min="06:00"
						max="22:55"
						step="300"
					/>
					<ArrowRightIcon className="size-4 text-gray-400" />
					<input
						type="time"
						name="endTime"
						value={field.value?.[1] || ""}
						onChange={handleChange}
						className="block w-1/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						min="06:05"
						max="23:00"
						step="300"
					/>
				</div>
				{meta.touched && meta.error ? (
					<div className="text-red-600 text-sm pt-2">
						{meta.error}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default TimeRangePicker;
