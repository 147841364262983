import React, { useEffect, useState } from "react";
import useFetch from "../../../shared/hooks/useFetch";

import BehandelaarItem from "./BehandelaarItem";
import Heading from "../../../shared/components/UIElements/Heading";
import NewBehandelaar from "./NewBehandelaar";
import { useUser } from "@clerk/clerk-react";
import SkeletonLoading from "../../../shared/components/UIElements/SkeletonLoading";
import EmptyState from "../../../shared/components/Tables/EmptyState";
import { UsersIcon } from "@heroicons/react/24/outline";

const BehandelaarTable = () => {
	const fetchBehandelaars = useFetch();
	const { user } = useUser();

	const [behandelaars, setBehandelaars] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const reload = async () => {
		const behandelaarsReq = await fetchBehandelaars(
			`/api/behandelaars/${user.id}`,
			"GET"
		);
		setBehandelaars(behandelaarsReq);
		setIsLoading(false);
	};

	useEffect(() => {
		const getBehandelaars = async () => {
			reload();
		};

		getBehandelaars();
	}, [fetchBehandelaars]);

	if (!isLoading && behandelaars.length === 0) {
		return (
			<>
				<div className="flex justify-between gap-8">
					<div>
						<Heading size="2" className="">
							Behandelaars
						</Heading>
						<p className="text-gray-600 pb-6 pt-2">
							Pas hier de behandelaars aan die in je praktijk
							werken
						</p>
					</div>
					<div className="">
						<NewBehandelaar reload={reload} />
					</div>
				</div>{" "}
				<EmptyState
					title="Je hebt nog geen behandelaars toegevoegd"
					description="Voeg het team toe aan je account"
					icon={UsersIcon}
				>
					<NewBehandelaar reload={reload} />
				</EmptyState>
			</>
		);
	}

	return (
		<>
			<div className="flex justify-between gap-8">
				<div>
					<Heading size="2" className="">
						Behandelaars
					</Heading>
					<p className="text-gray-600 pb-6 pt-2">
						Pas hier de behandelaars aan die in je praktijk werken
					</p>
				</div>
				<div className="">
					<NewBehandelaar reload={reload} />
				</div>
			</div>
			<div className="flex flex-col">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-gray-50">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
										>
											Naam
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Functie
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Behandelingen
										</th>
										<th
											scope="col"
											className="relative px-6 py-3"
										>
											<span className="sr-only">
												Wijzigen
											</span>
										</th>
									</tr>
								</thead>
								{isLoading && (
									<SkeletonLoading
										rows={5}
										columns={4}
										components={[
											<div className="flex items-center gap-3">
												<div className="size-10 rounded-full bg-gray-200"></div>

												<div className="h-5 w-32 bg-gray-200 rounded"></div>
											</div>,
											<div className="h-5 w-26 bg-gray-200 rounded"></div>,
											<div className="h-5 w-26 bg-gray-200 rounded"></div>,
											<div className="flex gap-5">
												<div className="size-6 bg-gray-200 rounded"></div>
												<div className="size-6 bg-gray-200 rounded"></div>
											</div>,
										]}
									/>
								)}
								<tbody className="bg-white divide-y divide-gray-200">
									{behandelaars.map((behandelaar) => (
										<BehandelaarItem
											key={behandelaar._id}
											firstName={behandelaar.firstName}
											lastName={behandelaar.lastName}
											treatments={
												behandelaar.treatmentTypes
											}
											functie={
												behandelaar.occupation.title
											}
											extraFuncties={
												behandelaar.secondaryOccupations
											}
											functieID={
												behandelaar.occupation._id
											}
											treatmentTypes={
												behandelaar.treatmentTypes
											}
											id={behandelaar._id}
											reload={reload}
										/>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BehandelaarTable;
