import React from "react";

const Heading = (props) => {
	switch (props.size) {
		case "1":
			return (
				<h1
					className={`mt-2 text-3xl font-bold leading-8 ${
						props.className ? props.className : ""
					}`}
				>
					{props.children}
				</h1>
			);
		case "2":
			return (
				<h2
					className={`mt-2 text-2xl font-bold leading-7 ${
						props.className ? props.className : ""
					}`}
				>
					{props.children}
				</h2>
			);
		case "3":
			return (
				<h3
					className={`mt-2 text-xl font-bold leading-7 ${
						props.className ? props.className : ""
					}`}
				>
					{props.children}
				</h3>
			);
		case "4":
			return (
				<h4
					className={`mt-2 text-lg font-semibold leading-7 ${
						props.className ? props.className : ""
					}`}
				>
					{props.children}
				</h4>
			);
		default:
			return (
				<h1
					className={`mt-2 text-3xl font-extrabold tracking-tight leading-8 ${
						props.className ? props.className : ""
					}`}
				>
					{props.children}
				</h1>
			);
	}
};

export default Heading;
