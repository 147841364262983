import React from "react";
import Button from "../FormElements/Button";
import { PlusIcon } from "@heroicons/react/16/solid";
import { XMarkIcon } from "@heroicons/react/20/solid";

const EmptyState = ({
	title = "Geen resultaten gevonden",
	description = "Er is niks gevonden",
	icon: Icon = XMarkIcon,
	buttonUrl,
	buttonText,
	children,
}) => {
	return (
		<div className="bg-white border-slate-200 shadow-sm px-4 py-10 border rounded-3xl flex flex-col items-center">
			<div className="bg-brand-50 rounded-full p-2 w-fit mb-4">
				<Icon
					className="size-10 text-brand-700 bg-[#ede6f7] rounded-full p-2"
					aria-hidden="true"
				/>
			</div>
			<div className="text-center">
				<h3 className="text-xl font-semibold">{title}</h3>
				<p className="text-gray-500">{description}</p>
			</div>
			{children && <div className="mt-6">{children}</div>}
			{buttonText && (
				<Button to={buttonUrl} classNames="mt-6" iconPrefix={PlusIcon}>
					{buttonText}
				</Button>
			)}
		</div>
	);
};

export default EmptyState;
