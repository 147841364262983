import React from "react";

const SkeletonLoadingItem = ({
	width = "w-6",
	height = "h-2",
	color = "gray-300",
	rounded = "md",
}) => {
	return (
		<div
			className={`${width} ${height} animate-pulse bg-${color} rounded-${rounded}`}
		></div>
	);
};

export default SkeletonLoadingItem;
