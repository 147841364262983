import React, { useEffect } from "react";
import * as Frigade from "@frigade/react";
import { useFlow } from "@frigade/react";
import { useUser } from "@clerk/clerk-react";
import { CheckCircleIcon } from "@heroicons/react/16/solid";

const OnboardingChecklist = ({ setAllCompleted, isCompleted }) => {
	const { user } = useUser();
	const { flow } = useFlow("flow_l1E2bn90");

	useEffect(() => {
		if (flow) {
			setAllCompleted(flow.isCompleted);
		}
	}, [isCompleted, flow]);

	if (flow && flow.isCompleted) {
		return (
			<p className="text-green-600 font-semibold items-center flex">
				<CheckCircleIcon className="size-6 mr-2" /> Klaar! Naar de
				volgende stap.
			</p>
			// commment
		);
	}

	return (
		<Frigade.Checklist.Carousel
			flowId="flow_l1E2bn90"
			variables={{ user_id: user.id }}
			onComplete={setAllCompleted}
		/>
	);
};

export default OnboardingChecklist;

// import React, { useEffect, useState } from "react";

// import { useUser } from "@clerk/clerk-react";

// import Checklist, {
// 	useChecklist,
// 	useChecklistItem,
// } from "@dopt/react-checklist";
// import {
// 	CheckCircleIcon,
// 	PlusIcon,
// 	ArrowDownTrayIcon,
// } from "@heroicons/react/16/solid";

// import Button from "../../../shared/components/FormElements/Button";
// import PracticeInfoButton from "./PracticeInfoButton";

// const OnboardingChecklist = ({ setAllCompleted }) => {
// 	const checklist = useChecklist("onboarding-v2.checklist");

// 	const { user } = useUser();

// 	const checklistItemIds = [
// 		"onboarding-v2.praktijkinformatie",
// 		"onboarding-v2.behandelaars-toevoegen",
// 		"onboarding-v2.taakbalk",
// 	];
// 	const praktijkItem = useChecklistItem(checklistItemIds[0]);

// 	useEffect(() => {
// 		if (user.unsafeMetadata.praktijkNaam) {
// 			praktijkItem.complete();
// 		}
// 	}, [user]);

// 	useEffect(() => {
// 		if (checklist.completed) {
// 			setAllCompleted(true);
// 		}
// 	}, [checklist.completed]);

// 	if (checklist.completed) {
// 		return (
// 			<p className="text-green-600 font-semibold items-center flex">
// 				<CheckCircleIcon className="size-6 mr-2" /> Klaar! Naar de
// 				volgende stap.
// 			</p>
// 		);
// 	}

// 	return (
// 		<>
// 			<div className="home">
// 				<div className="checklist">
// 					<Checklist.Root>
// 						<Checklist.Header>
// 							<Checklist.Title>{checklist.title}</Checklist.Title>
// 						</Checklist.Header>
// 						<Checklist.Body className="text-gray-600">
// 							{checklist.body}
// 						</Checklist.Body>
// 						<Checklist.Progress
// 							value={checklist.count("done")}
// 							max={checklist.size}
// 							title="Voortgang"
// 							translate="yes"
// 						/>
// 						<Checklist.Items>
// 							{checklist.items.map((item, i) => (
// 								<Checklist.Item index={i} key={i}>
// 									<Checklist.ItemIcon>
// 										{item.completed ? (
// 											<Checklist.IconCompleted />
// 										) : item.skipped ? (
// 											<Checklist.IconSkipped />
// 										) : (
// 											<Checklist.IconActive />
// 										)}
// 									</Checklist.ItemIcon>
// 									<Checklist.ItemContent>
// 										<Checklist.ItemTitle
// 											disabled={item.done}
// 										>
// 											{item.title}
// 										</Checklist.ItemTitle>
// 										<Checklist.ItemBody
// 											disabled={item.done}
// 										>
// 											{item.body}
// 										</Checklist.ItemBody>

// 										{item.id === checklistItemIds[0] &&
// 											!item.completed && (
// 												<PracticeInfoButton />
// 											)}
// 										{item.id === checklistItemIds[1] &&
// 											!item.completed && (
// 												<Button
// 													to="/instellingen/behandelaars"
// 													classNames="w-fit mt-4"
// 													iconPrefix={PlusIcon}
// 													target="_blank"
// 												>
// 													Voeg je behandelaars toe
// 												</Button>
// 											)}
// 										{item.id === checklistItemIds[2] &&
// 											!item.completed && (
// 												<div className="mt-4">
// 													<Button
// 														to="/instellingen/koppeling"
// 														iconPrefix={
// 															ArrowDownTrayIcon
// 														}
// 													>
// 														Installeer de koppeling
// 													</Button>
// 												</div>
// 											)}
// 									</Checklist.ItemContent>
// 								</Checklist.Item>
// 							))}
// 						</Checklist.Items>
// 					</Checklist.Root>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

// export default OnboardingChecklist;
