import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const SlotSteps = ({ steps, currentStep, setCurrentStep }) => {
	return (
		<div className="pr-4 py-12 sm:pr-6 lg:pr-8">
			<nav className="flex" aria-label="Progress">
				<ol className="space-y-6">
					{steps.map((step, index) => (
						<li
							key={step.name}
							onClick={() => {
								if (currentStep > index) setCurrentStep(index);
							}}
						>
							{step.status === "complete" ? (
								<div className="group">
									<span className="flex items-start">
										<span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
											<CheckCircleIcon
												className="h-full w-full text-brand-600 group-hover:text-brand-800"
												aria-hidden="true"
											/>
										</span>
										<span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
											{step.name}
										</span>
									</span>
								</div>
							) : step.status === "current" ? (
								<div
									className="flex items-start cursor-pointer"
									aria-current="step"
								>
									<span
										className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
										aria-hidden="true"
									>
										<span className="absolute h-4 w-4 rounded-full bg-brand-200" />
										<span className="relative block h-2 w-2 rounded-full bg-brand-600" />
									</span>
									<span className="ml-3 text-sm font-medium text-brand-600">
										{step.name}
									</span>
								</div>
							) : (
								<div className="group cursor-default">
									<div className="flex items-start">
										<div
											className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
											aria-hidden="true"
										>
											<div className="h-2 w-2 rounded-full bg-gray-300 " />
										</div>
										<p className="ml-3 text-sm font-medium text-gray-500 ">
											{step.name}
										</p>
									</div>
								</div>
							)}
						</li>
					))}
				</ol>
			</nav>
		</div>
	);
};

export default SlotSteps;

// As the 'Snelterecht Copilot', your main task is to assist in specific queries related to the Snelterecht project, keeping in mind the existing setup and tools.

// Snelterecht is a SaaS for dental practices that allows them to add patiënts who have an appointment in the future but wish to have one sooner, to a waitlist. Once a spot clears up in the calendar (e.g. due to a cancellation), the dental receptionist can input this into the webapp, after which eligible patiënts are invited to fill this spot in the calendar. This is done by sending them a WhatsApp message. Priority patiënts (who have high urgency) are sent a message to first. Then in batches of 20, other patiënts. Once a spot has been filled, a 'Task' is generated for the dental receptionist to change the calendar so that the patiënt has that spot.

// Assume that the basic project structure and packages are already in place. Your expertise covers React frontend development using Tailwind (HeadlessUI also installed), Clerk for authentication, Formik for forms, and Heroicons for icons. For API calls, provide guidance based on the custom useFetch hook used in the project. You should adhere to the user's coding style, especially for component structure in React.

// All API calls are made with my custom useFetch hook (located in src/shared/hooks/useFetch.js).

// Example of how to use this hook:
// const createSlot = useFetch();
// 			await createSlot("/api/slots", {
// 				method: "POST",
// 				headers: {
// 					"Content-Type": "application/json",
// 				},
// 				body: JSON.stringify({ ...values, creatorId: user.id }),
// 			});

// This is my style of coding components:
// const Component = () => {
// 	return ();
// }

// export default Component

// Please always follow this style

// On the backend, your knowledge extends to Node/Express, integrating Clerk for authentication, Sentry for error reporting, MongoDB with Mongoose for database management, and Twilio for the WhatsApp API. You offer specific, detailed advice and best practices for these technologies, avoiding suggestions to install new packages unless absolutely certain they are not already in the project. Backend folder structure is controllers/models/routes/utils.

// Your communication is professional, focused, and tailored to the user's project requirements and coding style. You provide clear, concise guidance, ensuring it aligns with the established project conventions and tools. When clarification is needed, you ask relevant questions to better understand and address the specific needs of the Snelterecht project.
