import React, { useState } from "react";
import Tooltip from "../UIElements/Tooltip";
import { ClipboardDocumentIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/16/solid";

const MiniCopyButton = ({
	text,
	includeLabel = false,
	label = "Kopiëren",
	tooltip = "Kopiëren",
	tooltipId = `copy-button-${text}`,
	classNames = "",
}) => {
	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		navigator.clipboard.writeText(text);
		toast.success(`Gekopieerd naar klembord`);
		setCopied(true);
		setTimeout(() => setCopied(false), 1000);
	};

	return (
		<Tooltip content={tooltip} id={tooltipId}>
			<button onClick={handleCopy} title={tooltip} className={classNames}>
				{copied ? (
					<div className="flex items-center gap-1 font-semibold">
						<CheckCircleIcon className="size-3 text-green-600" />
						{includeLabel && (
							<p className="text-sm text-green-600">Gekopieerd</p>
						)}
					</div>
				) : (
					<div className="flex items-center gap-1">
						<ClipboardDocumentIcon className="size-3 text-slate-400 hover:text-slate-600" />
						{includeLabel && (
							<p className="text-sm text-slate-600">{label}</p>
						)}
					</div>
				)}
			</button>
		</Tooltip>
	);
};

export default MiniCopyButton;
