import React, { useRef } from "react";
import Button from "./Button";
import { ClipboardDocumentIcon } from "@heroicons/react/16/solid";
import { toast } from "react-toastify";

const CopyText = ({ text }) => {
	const inputRef = useRef(null);

	const handleCopy = () => {
		const input = inputRef.current;
		input.select();
		toast.success("Gekopieerd naar je klembord", {
			position: "bottom-center",
			theme: "light",
			hideProgressBar: true,
			autoClose: 2000,
		});
		document.execCommand("copy");
	};

	return (
		<div className="mt-2 relative rounded-md shadow-sm">
			<input
				ref={inputRef}
				value={text}
				readOnly
				className="block w-full border-0 py-2.5 text-gray-900 rounded-md ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
			/>
			<div className="absolute inset-y-0 right-0 pr-1 flex items-center">
				<Button
					onClick={handleCopy}
					size="medium"
					// ghost
					iconPrefix={ClipboardDocumentIcon}
				>
					Kopieer
				</Button>
			</div>
		</div>
	);
};

export default CopyText;
