import React, { useState } from "react";

import CheckTask from "./CheckTask";
import TaskDetail from "../pages/TaskDetail";

const TasksListItem = ({ setTasks, task, isChecked }) => {
  const [openModal, setOpenModal] = useState(false);
  const formatDate = (date) => {
    const options = { month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("nl-NL", options);
  };

  return (
    <>
      <div className="flex items-center space-x-4 pb-6">
        <CheckTask task={task} isChecked={isChecked} setTasks={setTasks} />

        <div
          style={{
            opacity: isChecked ? "70%" : "100%",
            textDecoration: isChecked ? "line-through" : "none",
          }}
          onClick={() => setOpenModal(true)}
          className="flex-1 cursor-pointer transition-opacity duration-200 hover:opacity-75"
        >
        <div className="hover:opacity-60 duration-200">
          <p className="font-medium">
            Verzet de afspraak van{" "}
            {task.patient.name}
          </p>
          <p className="text-sm text-[#6D6D6D]">
            {task.patient.appointment?.datePlanned &&
              formatDate(task.patient.appointment?.datePlanned)}{" "}
            → {formatDate(task?.slot?.date)} om {task?.slot?.startTime}
          </p>
        </div>
        </div>
      </div>
      <TaskDetail
        taskId={task._id}
        open={openModal}
        setOpen={setOpenModal}
        setTasks={setTasks}
        taskItem={task}
      />
    </>
  );
};

export default TasksListItem;
