import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
	unformattedDate: Yup.mixed().nullable().required("Vul de datum in"),
	time: Yup.array()
		.of(
			Yup.string().matches(
				/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
				"Ongeldige tijd"
			)
		)
		.min(2, "Start- en eindtijd zijn verplicht")
		.test("is-valid-range", "Eindtijd moet na starttijd zijn", (value) => {
			if (value && value.length === 2) {
				return value[1] > value[0];
			}
			return true;
		})
		.required("Vul de start- en eindtijd van het agendagat in"),
	behandelaar: Yup.string().required("Behandelaar is verplicht"),
});
