import React, { createContext, useContext, useState, useCallback } from "react";

const TaskContext = createContext();

export const useTaskContext = () => useContext(TaskContext);

export const TaskProvider = ({ children }) => {
	const [tasksUpdated, setTasksUpdated] = useState(0); // Could also use boolean toggle

	const triggerTaskUpdate = useCallback(() => {
		setTasksUpdated((prev) => prev + 1);
	}, []);

	return (
		<TaskContext.Provider value={{ tasksUpdated, triggerTaskUpdate }}>
			{children}
		</TaskContext.Provider>
	);
};
