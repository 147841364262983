import React, { useState, useEffect } from "react";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../shared/hooks/useFetch";
import Table from "../../shared/components/Tables/Table";
import { ClipboardDocumentIcon } from "@heroicons/react/16/solid";
import { toast } from "react-toastify";
import FormattedDate from "../../shared/components/util/FormattedDate";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import MiniCopyButton from "../../shared/components/FormElements/MiniCopyButton";
import FormattedPhoneNumber from "../../shared/components/util/FormattedPhoneNumber";
import PatientDetail from "../components/PatientDetail";

const ErroredPatients = () => {
	const [deletedPatients, setDeletedPatients] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [detailPatientId, setDetailPatientId] = useState(null);
	const [openDetail, setOpenDetail] = useState(false);

	const { user } = useUser();
	const fetchPatients = useFetch();

	const errorDictionary = {
		63024: "Patiënt heeft geen WhatsApp",
		63018: "Te veel berichten gestuurd (neem contact op met ons via de chat rechtsonderin)",
		21211: "Ongeldig mobiel telefoonnummer (moet beginnen met +316)",
		63016: "Los bericht sturen mislukt (neem contact op met ons via de chat rechtsonderin)",
	};

	const reload = async () => {
		setIsLoading(true);
		const response = await fetchPatients(
			`/api/patients/user/${user.id}/errored`
		);
		setDeletedPatients(response);
		setIsLoading(false);
	};

	useEffect(() => {
		reload();
	}, [user, fetchPatients]);

	const openRow = (row) => {
		console.log(row._id);
		setDetailPatientId(row._id);
		setOpenDetail(true);
	};

	return (
		<Container>
			<MiniBackButton to="/patienten/all" />
			<Heading>Berichten die we niet hebben kunnen afleveren</Heading>
			<p className="text-slate-600 mt-4">
				Als een patiënt geen WhatsApp heeft, of het telefoonnummer klopt
				niet, kunnen we deze geen berichten sturen.
			</p>

			<Table
				data={deletedPatients}
				isLoading={isLoading}
				onRowClick={openRow}
			>
				<th
					scope="col"
					className="pr-3 py-3.5 pl-6 text-left text-sm font-semibold text-gray-900"
					accessor="fullName"
					render={(row) => (
						<div className="flex">
							<span className="text-black font-medium mr-2">
								{row.name}
							</span>
						</div>
					)}
				>
					Naam
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					accessor="phoneNumber"
					render={(row) => (
						<FormattedPhoneNumber
							phoneNumber={row.phoneNumber || row.phoneNumbers[0]}
						/>
					)}
				>
					Telefoonnummer
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					accessor="latestErrorMessage"
					render={(row) => (
						<span>
							{row.errors.codes.length > 0
								? errorDictionary[
										row.errors.codes[
											row.errors.codes.length - 1
										]
								  ] || "Onbekende fout"
								: "Geen foutmeldingen"}
						</span>
					)}
				>
					Laatste foutmelding
				</th>
			</Table>

			<PatientDetail
				patientId={detailPatientId}
				open={openDetail}
				setOpen={setOpenDetail}
				reload={reload}
				onPreviewPage={true}
			/>
		</Container>
	);
};

export default ErroredPatients;
