import React from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

const Button = ({
	size,
	ghost,
	mini,
	iconSuffix: IconSuffix,
	iconPrefix: IconPrefix,
	fullWidth = false,
	disabled = false,
	...props
}) => {
	const baseClasses = classNames(
		"font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:scale-105 flex items-center w-fit",
		{
			"px-3 py-2 text-sm rounded-lg": size === "medium",
			"px-6 py-3 text-base rounded-xl": size === "large" || !size,
			"bg-gradient-to-b from-[#7634DE] to-[#6D28D9] text-white": !ghost,
			"bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50":
				ghost,
			"w-full justify-center": fullWidth,
			"opacity-70 bg-gray-400 hover:bg-gray-400 hover:scale-100 cursor-not-allowed":
				disabled,
		},
		props.classNames
	);

	const content = (
		<>
			{IconPrefix && (
				<IconPrefix
					className={`${props.children ? "mr-2 -ml-1" : ""} h-5 w-5`}
					aria-hidden="true"
				/>
			)}
			{props.children}
			{IconSuffix && (
				<IconSuffix
					className={`${props.children ? "ml-2 -mr-1" : ""} h-5 w-5`}
					aria-hidden="true"
				/>
			)}
		</>
	);

	if (props.href && !props.onClick) {
		return (
			<a className={baseClasses} href={props.href} target={props.target}>
				{content}
			</a>
		);
	} else if (props.href && props.onClick) {
		return (
			<a
				className={baseClasses}
				href={props.href}
				target={props.target}
				onClick={props.onClick}
			>
				{content}
			</a>
		);
	} else if (props.to) {
		return (
			<Link to={props.to} className={baseClasses} target={props.target}>
				{content}
			</Link>
		);
	} else {
		return (
			<button
				className={baseClasses}
				onClick={props.onClick}
				disabled={disabled}
				{...props}
			>
				{content}
			</button>
		);
	}
};

export default Button;
