import React from "react";
import classNames from "classnames";
import { CheckIcon } from "@heroicons/react/16/solid";

const StatusPill = ({ status, size = "small", long = false }) => {
	const statusClass = classNames({
		"bg-green-100 text-green-800": status === "claimed",
		"bg-orange-100 text-orange-800": status === "open",
		"bg-gray-100 text-gray-800": status === "draft" || status === "deleted",
		"bg-red-100 text-red-800": status === "failed",
		"text-xs px-2": size === "small",
		"text-sm px-3 py-1.5": size === "medium",
		"inline-flex leading-5 font-semibold rounded-full items-center": true,
	});
	return (
		<span className={statusClass}>
			{status === "claimed" && size === "medium" && (
				<CheckIcon className="h-4 w-4 mr-0.5 -ml-1" />
			)}
			{(() => {
				switch (status) {
					case "open":
						return long ? "Open agenda plek" : "Open";
					case "draft":
						return long ? "Concept" : "Concept";
					case "failed":
						return long
							? "Alle patiënten kunnen niet"
							: "Niemand kan";
					case "deleted":
						return long ? "Verwijderd" : "Verwijderd";
					default:
						return long ? "Gevulde lege plek" : "Gevuld";
				}
			})()}
		</span>
	);
};

export default StatusPill;
