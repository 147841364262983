import React from "react";
import SkeletonLoading from "../UIElements/SkeletonLoading";
import EmptyState from "./EmptyState";
import classNames from "classnames";

const Table = ({ children, data, isLoading, onRowClick }) => {
	if (!isLoading && data.length === 0) {
		return (
			<div className="mt-10">
				<EmptyState />
			</div>
		);
	}

	return (
		<div className="mt-8 flow-root">
			<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
						<table className="min-w-full divide-y divide-gray-300">
							<thead className="bg-gray-50">
								<tr>{children}</tr>
							</thead>
							{isLoading && (
								<SkeletonLoading
									rows={5}
									columns={React.Children.count(children)}
								/>
							)}
							<tbody className="divide-y divide-gray-200 bg-white">
								{data.map((row, rowIndex) => (
									<tr
										key={rowIndex}
										className={classNames(
											row.className || "",
											{
												"hover:bg-slate-50 cursor-pointer duration-200 transition-all":
													onRowClick,
											}
										)}
										onClick={() =>
											onRowClick && onRowClick(row)
										}
									>
										{React.Children.map(
											children,
											(child) => (
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 first:pl-6">
													{child.props.render
														? child.props.render(
																row
														  )
														: row[
																child.props
																	.accessor
														  ]}
												</td>
											)
										)}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Table;
