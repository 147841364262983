import { SignIn } from "@clerk/clerk-react";
import React from "react";

const SignInForm = () => {
	return (
		<div className="mt-signin">
			<SignIn
				appearance={{
					elements: {
						header: "hidden",
						card: "w-full p-0 m-0 bg-transparent shadow-none",
						rootBox: "w-full",
						formFieldInput:
							"rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6",
						formButtonPrimary:
							"bg-brand-intense hover:bg-brand-700 hover:scale-105 transition ease-in-out duration-300 rounded-xl normal-case text-base shadow-sm font-semibold tracking-normal py-3 text-white",
						footerActionLink: "text-brand-600 hover:text-brand-700",
						footer: "hidden",
					},
				}}
			/>
		</div>
	);
};

export default SignInForm;
