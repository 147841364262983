import React from "react";

import { useUser } from "@clerk/clerk-react";

import ProfileImage from "../../../users/components/ProfileImage";
import { Link } from "react-router-dom";

const ProfileItem = () => {
	const { user } = useUser();

	return (
		<Link to="/instellingen" className="flex-shrink-0 w-full group block">
			<div className="flex items-center">
				<div>
					<ProfileImage
						className="inline-block h-9 w-9 rounded-full"
						name={user.id}
					/>
				</div>
				<div className="ml-3">
					<p
						className="text-sm font-medium text-gray-700 group-hover:text-gray-900"
						data-record="true"
					>
						{user.unsafeMetadata.praktijkNaam ||
							`${user.firstName} ${user.lastName}`}
					</p>
					<p
						className="text-xs font-medium text-gray-500 group-hover:text-gray-700"
						data-record="true"
					>
						{user.unsafeMetadata.praktijkNaam &&
							`${user.firstName} ${user.lastName}`}
					</p>
				</div>
			</div>
		</Link>
	);
};

export default ProfileItem;
