import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { ArrowLeftIcon } from "@heroicons/react/16/solid";
import { CalendarIcon, ClockIcon, UserIcon } from "@heroicons/react/24/outline";
import useFetch from "../../shared/hooks/useFetch";
import Button from "../../shared/components/FormElements/Button";
import FormattedDate from "../../shared/components/util/FormattedDate";

import SelectPatientsTable from "../../patients/components/SelectPatientsTable";

const NewSlotPatients = ({ slotID, onChosenPatients, onBack }) => {
	const [patients, setPatients] = useState([]);
	const [notAvailablePatients, setNotAvailablePatients] = useState([]);
	const [slot, setSlot] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const [selectedPatients, setSelectedPatients] = useState([]);

	const fetchPatients = useFetch();

	useEffect(() => {
		const getPatients = async () => {
			const response = await fetchPatients(
				`/api/slots/${slotID}/patients`
			);
			setPatients(response.eligiblePatients);
			setNotAvailablePatients(response.notAvailablePatients);
			setSlot(response.slot);
			setIsLoading(false);
		};
		getPatients();
	}, [slotID]);

	const choosePatients = async () => {
		await fetchPatients(`/api/slots/${slotID}/patients`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ patients: selectedPatients }),
		});
		onChosenPatients();
	};

	return (
		<>
			<div>
				<h3 className="text-lg leading-6 font-bold text-gray-900">
					Welke patiënten wil je uitnodigen?
					<span className="font-medium text-gray-500 text-sm pl-2">
						({patients?.length} komen in aanmerking)
					</span>
				</h3>
				<p className="mt-1 text-sm text-gray-500">
					Deze patiënten komen in aanmerking voor de lege plek in de
					agenda, wie wil je uitnodigen?
				</p>
				{slot.behandelaar && (
					<div className="flex mt-5 space-x-3 text-sm text-gray-500">
						<p className="flex items-center bg-gray-50 py-2 px-3 rounded-full border border-gray-200">
							<UserIcon className="h-5 w-5 text-gray-500 mr-3" />
							<p className="text-gray-600">
								{slot.behandelaar.firstName}{" "}
								{slot.behandelaar.lastName}
							</p>
						</p>
						<p className="flex items-center bg-gray-50 py-2 px-3 rounded-full border border-gray-200">
							<CalendarIcon className="h-5 w-5 text-gray-500 mr-3" />
							<FormattedDate date={slot.date} />
						</p>
						<p className="flex items-center bg-gray-50 py-2 px-3 rounded-full border border-gray-200">
							<ClockIcon className="h-5 w-5 text-gray-500 mr-3" />
							<p className="text-gray-600">
								{slot.startTime} - {slot.endTime} uur (
								{slot.duration} min)
							</p>
						</p>
					</div>
				)}

				<SelectPatientsTable
					patients={patients}
					notAvailablePatients={notAvailablePatients}
					isLoading={isLoading}
					selectedPatients={selectedPatients}
					setSelectedPatients={setSelectedPatients}
					autoCheckAll={true}
				/>

				<div className="flex gap-2">
					<Button
						onClick={onBack}
						classNames="mt-4"
						ghost
						iconPrefix={ArrowLeftIcon}
					>
						Terug
					</Button>

					<Button
						onClick={choosePatients}
						classNames="mt-4"
						disabled={selectedPatients.length === 0}
					>
						Naar benaderplan{" "}
						<span className="font-normal pl-1 opacity-80">
							({selectedPatients.length} patiënten)
						</span>
					</Button>
				</div>
			</div>
		</>
	);
};

export default NewSlotPatients;
