import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import useFetch from "../../shared/hooks/useFetch";

import {
	ArrowLeftIcon,
	ArrowRightIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	PlusIcon,
} from "@heroicons/react/16/solid";
import { useNavigate } from "react-router-dom";
import { useChecklistItem } from "@dopt/react-checklist";
import posthog from "posthog-js";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import Select from "../../shared/components/FormElements/Select";
import SelectBehandelaar from "../../practice/components/Behandelaren/SelectBehandelaar";
import Checkbox from "../../shared/components/FormElements/Checkbox";
import DatePicker from "../../shared/components/FormElements/DatePicker";
import Textarea from "../../shared/components/FormElements/Textarea";
import MultiplePhoneInputs from "../../shared/components/FormElements/MultiplePhoneInputs";
import PatientDeletedWarning from "./PatientDeletedWarning";
import PrefilledFromDesktopInfo from "./PrefilledFromDesktopInfo";
import DesktopAppNotice from "./DesktopAppNotice";
import RadioGroup from "../../shared/components/FormElements/RadioGroup";
import { isValidPhoneNumber } from "react-phone-number-input";

const NewPatientForm = ({
	reload,
	setOpen,
	isEditing = false,
	initialPatient,
	initialValues = {
		phoneNumber: "+31 6",
	},
	fromDesktopApp = false,
}) => {
	const { user } = useUser();
	const navigate = useNavigate();
	const createPatient = useFetch();

	const [step, setStep] = useState(fromDesktopApp ? 2 : 1);

	const [selectedOccupation, setSelectedOccupation] = useState(null);
	const [treatments, setTreatments] = useState([]);
	const [behandelaarTreatments, setBehandelaarTreatments] = useState([]);
	const [availableTreatments, setAvailableTreatments] = useState([]);

	// Prefilling from desktop
	const [prefilledValues, setPrefilledValues] = useState(null);
	const [isLoadingIntegrationResult, setIsLoadingIntegrationResult] =
		useState(false);
	const [foundPhoneNumbers, setFoundPhoneNumbers] = useState([]);

	const [showAdvanced, setShowAdvanced] = useState(false);

	const clItemPatient = useChecklistItem("onboarding.patient");

	useEffect(() => {
		const filteredTreatments = treatments.filter((treatment) =>
			behandelaarTreatments.includes(treatment.value),
		);
		setAvailableTreatments(filteredTreatments);
	}, [behandelaarTreatments, treatments]);

	const handleSubmit = async (values, actions) => {
		if (step === 1) {
			setStep(2);
			actions.setTouched({});
			actions.setSubmitting(false);
		} else {
			try {
				const behandelingDate = values.unformattedDate;
				const date = behandelingDate
					? behandelingDate.format("YYYY-MM-DD")
					: null;

				const inviteFrom = values.unformattedInviteFrom
					? values.unformattedInviteFrom.format("YYYY-MM-DD")
					: null;

				let body = {
					...values,
					datumGeplandeAfspraak: date,
					creatorId: user.id,
					inviteFrom,
					source: fromDesktopApp ? "desktop-app" : "manual",
				};

				let response;

				if (isEditing) {
					body.patientId = initialPatient._id;
					response = await createPatient(
						`/api/patients/${initialPatient.id}`,
						{
							method: "PUT",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(body),
						},
					);

					toast.success("Patiënt geüpdate", {
						position: "bottom-center",
					});
					setOpen(false);
					navigate(`/patienten/all`);
				} else {
					response = await createPatient("/api/patients", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(body),
					});

					clItemPatient.complete();
					if (response._id) {
						toast.success(
							"Patiënt succesvol toegevoegd aan Snelterecht",
							{
								position: "bottom-center",
							},
						);
						navigate(`/patienten/${response._id}`);
					} else {
						toast.error(response.message, {
							position: "bottom-center",
							autoClose: 500000,
						});
					}
				}

				setOpen(false);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const validationSchema = Yup.object().shape({
		// Validation for step 1 fields
		firstName: Yup.string().required("Voornaam is verplicht"),
		lastName: Yup.string().required("Achternaam is verplicht"),
		phoneNumber: Yup.string()
			// .matches(
			// 	/^\+?[1-9]\d{10}$|^06\d{8}$/,
			// 	// /^(?:(\+316|06)\d{8}|(\+?\d{1,4}[ -]?\d{1,4}[ -]?\d{4,15}))$/,
			// 	"Telefoonnummer is niet geldig"
			// )
			.test("phone-number", "Telefoonnummer is niet geldig", (value) =>
				isValidPhoneNumber(value),
			)
			.required("Telefoonnummer is verplicht"),

		phoneNumber2: Yup.string()
			.matches(
				/^\+?[1-9]\d{10}$|^06\d{8}$/,
				"Telefoonnummer is niet geldig",
			)
			.nullable(),
		phoneNumber3: Yup.string()
			.matches(
				/^\+?[1-9]\d{10}$|^06\d{8}$/,
				"Telefoonnummer is niet geldig",
			)
			.nullable(),
		patientnummer: Yup.string().required("Patiëntnummer is verplicht"),
		// Validation for step 2 fields
		behandelingDuur:
			step === 2
				? Yup.number()
						.min(
							1,
							"Duur van de behandeling moet langer dan 0 minuten zijn",
						)
						.required(
							"Duur van de behandeling is een verplicht veld",
						)
				: Yup.number(),
		behandelaar:
			step === 2
				? Yup.string().required("Behandelaar is verplicht")
				: Yup.string(),
		priority: step === 2 ? Yup.boolean() : Yup.boolean(),
	});

	useEffect(() => {
		const fetchTreatments = async () => {
			try {
				const response = await createPatient("/api/treatments");
				const formattedResponse = response.map((treatment) => ({
					label: treatment.name,
					value: treatment._id,
				}));
				setTreatments(formattedResponse);
			} catch (error) {
				console.error("Error fetching treatments:", error);
				toast.error("Fout bij het ophalen van de behandelingen", {
					position: "bottom-center",
				});
			}
		};

		fetchTreatments();
	}, []);

	return (
		<Formik
			initialValues={
				process.env.REACT_APP_STAGING === "true" &&
				!isEditing &&
				!fromDesktopApp
					? {
							firstName: "Jan",
							lastName: "Janssen",
							patientnummer: "123456",
							phoneNumber: "+31618336824",
							behandelingDuur: 30,
						}
					: isEditing
						? initialPatient
						: { ...initialValues, ...prefilledValues }
			}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, setFieldValue }) => (
				<Form autoComplete="off">
					<div className="flex flex-wrap px-4 py-0 sm:px-6 h-screen content-start">
						{step === 1 && (
							<>
								<Input
									label="Voornaam"
									name="firstName"
									type="text"
									autoComplete="off"
								/>
								<Input
									label="Achternaam"
									name="lastName"
									type="text"
									autoComplete="off"
								/>
								<Input
									label="Patiëntnummer"
									name="patientnummer"
									type="text"
									width="1/2"
								/>
								<MultiplePhoneInputs
									amountOfPhones={
										values.phoneNumber3
											? 3
											: values.phoneNumber2
												? 2
												: 1
									}
									maxPhones={3}
								/>

								<PatientDeletedWarning
									patientId={values.patientnummer}
								/>
							</>
						)}
						{step === 2 && (
							<>
								{fromDesktopApp && (
									<PrefilledFromDesktopInfo
										setStep={setStep}
										values={values}
										setPrefilledValues={setPrefilledValues}
										setIsLoadingIntegrationResult={
											setIsLoadingIntegrationResult
										}
										setFoundPhoneNumbers={
											setFoundPhoneNumbers
										}
										setFieldValue={setFieldValue}
									/>
								)}
								{fromDesktopApp &&
									!isLoadingIntegrationResult &&
									prefilledValues &&
									!prefilledValues.firstName && (
										<Input
											label="Voornaam"
											name="firstName"
											type="text"
											autoComplete="off"
										/>
									)}

								{fromDesktopApp &&
									!isLoadingIntegrationResult &&
									prefilledValues &&
									!prefilledValues.lastName && (
										<Input
											label="Achternaam"
											name="lastName"
											type="text"
											autoComplete="off"
										/>
									)}

								{fromDesktopApp &&
									!isLoadingIntegrationResult &&
									prefilledValues &&
									!prefilledValues.patientnummer && (
										<Input
											label="Patiëntnummer"
											name="patientnummer"
											type="text"
											width="1/2"
										/>
									)}

								{fromDesktopApp &&
									!isLoadingIntegrationResult &&
									prefilledValues &&
									(foundPhoneNumbers.length === 0 ||
										!foundPhoneNumbers[0].tel) &&
									(!prefilledValues.phoneNumber ||
										prefilledValues.phoneNumber.length <
											10) && (
										<MultiplePhoneInputs
											amountOfPhones={
												values.phoneNumber3
													? 3
													: values.phoneNumber2
														? 2
														: 1
											}
										/>
									)}

								<div>
									<DatePicker
										label="Datum van de geplande afspraak"
										name="unformattedDate"
										tooltip="Laat leeg als de patiënt nog geen afspraak heeft staan"
									/>
									<Input
										label="Duur van de behandeling"
										name="behandelingDuur"
										type="number"
										width="1/2"
										suffix="minuten"
									/>
									<SelectBehandelaar
										label="Wie is de behandelaar voor deze afspraak?"
										name="behandelaar"
										includeOccupations={false}
										setSelectedOccupation={
											setSelectedOccupation
										}
										setBehandelaarTreatments={
											setBehandelaarTreatments
										}
										value={values.behandelaar}
										defaultValue={values.behandelaar}
									/>
									{values.behandelaar &&
										selectedOccupation && (
											<Checkbox
												label={`Mogen andere ${selectedOccupation.plural} deze patiënt ook behandelen?`}
												name="otherBehandelaars"
											/>
										)}
									{/* {values.behandelaar &&
										selectedOccupation && (
											<RadioGroup
												label={`Mogen andere ${selectedOccupation.plural} deze patiënt ook behandelen?`}
												name="otherBehandelaars"
											/>
										)} */}
									{selectedOccupation &&
										selectedOccupation._id ===
											"659fcad31bfb44314301ca81" && (
											<Select
												label="Soort behandeling"
												name="treatment"
												options={
													availableTreatments.length >
													0
														? availableTreatments
														: treatments
												}
												required
											/>
										)}
									<Checkbox
										label="Deze patiënt is spoed"
										description="Geef voorrang bij de uitnodigingen."
										name="priority"
									/>

									<div className="pt-10 w-full">
										<button
											onClick={(e) => {
												e.preventDefault();
												setShowAdvanced(!showAdvanced);
											}}
											className="text-sm uppercase font-semibold text-gray-500 flex items-center"
										>
											Geavanceerd{" "}
											{showAdvanced ? (
												<ChevronUpIcon className="h-4 w-4 ml-1" />
											) : (
												<ChevronDownIcon className="h-4 w-4 ml-1" />
											)}
										</button>
										{showAdvanced && (
											<div className="-mt-2 mb-6">
												<DatePicker
													label="Oproepen vanaf"
													name="unformattedInviteFrom"
													optional
												/>
												<Textarea
													label="Opmerkingen"
													name="notes"
													rows={3}
													optional
												/>
												<p className="text-sm mt-2 mb-4 text-gray-500">
													Dit krijgt de patiënt niet
													te zien.
												</p>
											</div>
										)}
									</div>
								</div>
							</>
						)}
					</div>

					<div className="sticky bottom-0">
						{/* {step === 1 && !fromDesktopApp && (
							<div className="mb-6 mx-6">
								<DesktopAppNotice />
							</div>
						)} */}
						<div className=" bg-white py-4 shadow border-t border-gray-200 flex justify-between gap-2 px-4 sm:px-6">
							{step === 2 && (
								<Button
									ghost
									iconPrefix={ArrowLeftIcon}
									type="button"
									onClick={() => setStep(1)}
								>
									Terug
								</Button>
							)}
							<Button
								type="submit"
								fullWidth
								disabled={isSubmitting}
								iconSuffix={
									step === 1 ? ArrowRightIcon : PlusIcon
								}
							>
								{step === 1
									? "Volgende"
									: isEditing
										? "Patiënt updaten"
										: "Patiënt toevoegen"}
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default NewPatientForm;
