import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { CheckIcon } from "@heroicons/react/16/solid";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";

import useFetch from "../../shared/hooks/useFetch";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import Heading from "../../shared/components/UIElements/Heading";
import SwitchToggle from "../../shared/components/FormElements/Switch";
import OneSignal from "react-onesignal";

const NotificationSettings = () => {
	const [settings, setSettings] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const updatePractice = useFetch();

	const { user } = useUser();

	const handleSubmit = async (values) => {
		await updatePractice(`/api/practice/user/${user.id}/settings`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(values),
		});
		setIsLoading(false);
		setSettings(values);

		toast.success("Praktijkgegevens gewijzigd", {
			position: "bottom-center",
			theme: "light",
			hideProgressBar: true,
			autoClose: 2000,
			icon: <CheckIcon className=" text-green-500" aria-hidden="true" />,
		});
	};

	const turnOnPushNotifications = async (e) => {
		e.preventDefault();
		console.log(OneSignal.User);
		await OneSignal.Slidedown.promptPush({ force: true });
	};

	useEffect(() => {
		const getPractice = async () => {
			const practiceReq = await updatePractice(
				`/api/practice/user/${user.id}`,
				"GET"
			);

			setSettings(practiceReq.settings);
			setIsLoading(false);
		};

		getPractice();
	}, [updatePractice, user]);

	return (
		<>
			<Heading size="2">Meldingen</Heading>
			<p className="pt-2 pb-6 text-gray-600">
				Krijg een melding zodra er een lege plek in de agenda wordt
				opgevuld. Zo weet je dat je een afspraak in de praktijkagenda
				moet verzetten.
			</p>
			<Formik
				initialValues={{
					sendEmailNotifications: settings.sendEmailNotifications,
					emailAddress: settings.emailAddress,
				}}
				enableReinitialize={true}
				onSubmit={handleSubmit}
			>
				{({ values, errors, touched }) => (
					<Form>
						<div>
							<div className="border-b border-gray-200 pb-6">
								<div className="flex items-center justify-between pt-8 pb-2">
									<span className="flex-grow flex flex-col">
										<p className="text-sm font-medium text-gray-9000">
											Push notificaties
										</p>
										<p className="text-sm text-gray-500">
											Stuur een push melding naar de
											receptie wanneer er een lege plek in
											de agenda wordt opgevuld
										</p>
									</span>

									{OneSignal.User.PushSubscription.optedIn ? (
										<p className="text-sm font-semibold text-green-600 flex items-center">
											<CheckIcon className="size-4 mr-2" />
											Ingeschakeld
										</p>
									) : (
										<button
											className="text-brand-600 font-semibold text-sm"
											onClick={turnOnPushNotifications}
										>
											Instellen
										</button>
									)}
								</div>
							</div>
							<div>
								<SwitchToggle
									label="E-mail meldingen"
									description="Stuur een e-mail naar de receptie wanneer er een lege plek in de agenda wordt opgevuld"
									name="sendEmailNotifications"
								/>
								{values.sendEmailNotifications && (
									<Input
										label="Naar welk e-mailadres mag de melding gestuurd worden?"
										description="Zorg ervoor dat de receptie hier altijd bij kan."
										name="emailAddress"
										type="email"
										required
										width="1/2"
									/>
								)}

								<Button
									type="submit"
									size="medium"
									classNames="mt-6"
								>
									Opslaan
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default NotificationSettings;
