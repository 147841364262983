import React, { useState } from "react";

import { StarIcon } from "@heroicons/react/24/solid";
import PriorityStar from "./PriorityStar";
import SidePanel from "../../shared/components/UIElements/SidePanel";
import PatientDetail from "./PatientDetail";
import FormattedPhoneNumber from "../../shared/components/util/FormattedPhoneNumber";
import { useNavigate, useParams } from "react-router-dom";
import { ExclamationTriangleIcon, MoonIcon } from "@heroicons/react/16/solid";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import FormattedDate from "../../shared/components/util/FormattedDate";
import BehandelaarAvatar from "../../practice/components/Behandelaren/BehandelaarAvatar";
import NotesTooltip from "./NotesTooltip";

// import DeleteBehandelaar from "./DeleteBehandelaar";
// import EditBehandelaar from "./EditBehandelaar";

const PatientsTableItem = ({
	name,
	patientNumber,
	id,
	behandelaar,
	priority,
	errorInfo = {},
	snoozeUntil,
	createdAt,
	appointmentDate,
	duration,
	notes,
}) => {
	const navigate = useNavigate();

	const openPatient = () => {
		navigate(`/patienten/${id}`);
	};

	return (
		<>
			<tr
				key={id}
				onClick={openPatient}
				className={`hover:bg-gray-50 cursor-pointer duration-200 transition-all ease-in-out`}
			>
				<td className="px-6 py-4 whitespace-nowrap">
					<div className="flex items-center">
						<div className="">
							<div className="text-sm font-medium text-gray-900 flex items-center">
								{name}
								{priority && <PriorityStar id={id} />}
								{snoozeUntil &&
									new Date(snoozeUntil) > new Date() && (
										<div className="ml-2 mt-0.5 cursor-help">
											<Tooltip
												content={`Patiënt is met vakantie t/m ${new Date(
													snoozeUntil
												).toLocaleDateString("nl-NL", {
													month: "long",
													day: "numeric",
												})}`}
												id={`snoozed_${id}`}
											>
												<MoonIcon className="h-4 w-4 text-slate-400" />
											</Tooltip>
										</div>
									)}
								{/* {errorInfo.count > 0 && (
									<div className="ml-2 mt-0.5 cursor-help">
										<Tooltip
											content={`Appjes niet afgeleverd`}
											id={`error_${id}`}
										>
											<ExclamationTriangleIcon className="h-4 w-4 text-red-500" />
										</Tooltip>
									</div>
								)} */}
							</div>
							<div className="text-sm text-gray-500">
								{patientNumber}
							</div>
						</div>
					</div>
				</td>

				<td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-600 flex items-center gap-x-3">
					<BehandelaarAvatar
						firstName={behandelaar?.firstName}
						lastName={behandelaar?.lastName}
					/>
					<div>
						<p className="text-black font-medium">
							{behandelaar?.firstName} {behandelaar?.lastName}
						</p>
						<p className="text-gray-500">
							{appointmentDate
								? (() => {
										const date = new Date(appointmentDate);
										const now = new Date();
										const options = {
											month: "long",
											day: "numeric",
											...(date.getFullYear() !==
												now.getFullYear() && {
												year: "numeric",
											}),
										};
										return date.toLocaleDateString(
											"nl-NL",
											options
										);
								  })()
								: "Niet ingepland"}{" "}
							({duration} min)
						</p>
					</div>
					{notes && (
						<div className="ml-3">
							<NotesTooltip notes={notes} patientId={id} />
						</div>
					)}
				</td>

				<td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-600">
					<FormattedDate date={createdAt} relative />
				</td>
			</tr>
		</>
	);
};

export default PatientsTableItem;
