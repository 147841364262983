import React from "react";
import {
	formatPhoneNumber,
	formatPhoneNumberIntl,
} from "react-phone-number-input";

const FormattedPhoneNumber = ({ phoneNumber }) => {
	return <>{formatPhoneNumberIntl(phoneNumber)}</>;

	// if (/^\+316\d{8}$/.test(phoneNumber)) {
	// 	return (
	// 		<>
	// 			{`${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
	// 				3,
	// 				4
	// 			)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(
	// 				6,
	// 				8
	// 			)} ${phoneNumber.slice(8, 10)} ${phoneNumber.slice(10)}`}
	// 		</>
	// 	);
	// } else if (/^06\d{8}$/.test(phoneNumber)) {
	// 	return (
	// 		<>
	// 			{`${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
	// 				2,
	// 				4
	// 			)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(
	// 				6,
	// 				8
	// 			)} ${phoneNumber.slice(8, 10)}`}
	// 		</>
	// 	);
	// } else {
	// 	return <>{phoneNumber}</>;
	// }
};

export default FormattedPhoneNumber;
