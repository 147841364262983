import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserProfile, useClerk } from "@clerk/clerk-react";
import {
	BellIcon,
	CreditCardIcon,
	FingerPrintIcon,
	UsersIcon,
	BuildingOfficeIcon,
	ReceiptPercentIcon,
	ComputerDesktopIcon,
} from "@heroicons/react/20/solid";
import Container from "../../shared/components/UIElements/Container";
import SettingsSideBar from "../components/settings/SettingsSideBar";
import BehandelaarTable from "../../practice/components/Behandelaren/BehandelaarTable";
import NotificationSettings from "../../practice/components/NotificationSettings";
import useFetch from "../../shared/hooks/useFetch";
import ManagePractices from "../components/settings/ManagePractices";
import ManageAccount from "../components/settings/ManageAccount";
import DownloadDesktopApp from "../../shared/pages/DownloadDesktopApp";
import SubscriptionSettings from "../../billing/pages/SubscriptionSettings";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/16/solid";
import InvitePreferences from "../components/settings/InvitePreferences";

const SettingsPage = () => {
	const { user } = useClerk();
	const fetchLink = useFetch();
	const [customerPortalLink, setCustomerPortalLink] = useState("");
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchCustomerPortalLink = async () => {
			try {
				const response = await fetchLink(
					`/api/billing/customer-portal/${user.id}/${user.publicMetadata.customerId}`
				);
				if (response) {
					setCustomerPortalLink(response);
				} else {
					console.error(
						"Failed to retrieve link for customer portal."
					);
				}
			} catch (error) {
				console.error("Error fetching customer portal link:", error);
			}
		};

		if (user.publicMetadata.customerId) {
			fetchCustomerPortalLink();
		}
	}, [user.id, user.publicMetadata.customerId, fetchLink]);

	const secondaryNavigation = [
		{
			title: "Beheer praktijk",
			items: [
				{
					name: "Behandelaars",
					href: "/instellingen/behandelaars",
					icon: UsersIcon,
					component: BehandelaarTable,
				},
				{
					name: "Praktijkinformatie",
					href: "/instellingen/praktijkinformatie",
					icon: BuildingOfficeIcon,
					component: ManagePractices,
				},
				{
					name: "Voorkeuren",
					href: "/instellingen/voorkeuren",
					icon: AdjustmentsHorizontalIcon,
					component: InvitePreferences,
				},
				{
					name: "Meldingen",
					href: "/instellingen/meldingen",
					icon: BellIcon,
					component: NotificationSettings,
				},
			],
		},
		{
			title: "Administratie",
			hideWhenNoCustomerId: true,
			items: [
				{
					name: "Beheer abonnement",
					href: "/instellingen/abonnement",
					icon: CreditCardIcon,
					component: SubscriptionSettings,
				},
				{
					name: "Inloggegevens",
					href: "/instellingen/inloggegevens",
					icon: FingerPrintIcon,
					component: ManageAccount,
				},
			],
		},
		{
			title: "",
			items: [
				{
					name: "Koppeling installeren",
					href: "/instellingen/koppeling",
					icon: ComputerDesktopIcon,
					component: DownloadDesktopApp,
				},
			],
		},
	];

	const getActiveItem = () => {
		const pathname = location.pathname.split("?")[0].replace(/\/$/, "");
		for (const section of secondaryNavigation) {
			for (const item of section.items) {
				if (pathname === item.href.replace(/\/$/, "")) {
					return item;
				}
			}
		}
		return secondaryNavigation[0].items[0];
	};

	const [activeItem, setActiveItem] = useState(getActiveItem());

	useEffect(() => {
		setActiveItem(getActiveItem());
	}, [location.pathname]);

	return (
		<div>
			<SettingsSideBar
				navItems={secondaryNavigation}
				setActiveItem={(item) => {
					setActiveItem(item);
					navigate(item.href);
				}}
				activeItem={activeItem}
			>
				<Container transparent>
					{activeItem.component
						? React.createElement(activeItem.component)
						: null}
				</Container>
			</SettingsSideBar>
		</div>
	);
};

export default SettingsPage;
