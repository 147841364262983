import React, { useState, useEffect } from "react";
import Modal from "../../shared/components/UIElements/Modal";
import SelectPatientsTable from "../../patients/components/SelectPatientsTable";
import Button from "../../shared/components/FormElements/Button";
import useFetch from "../../shared/hooks/useFetch";

const CreatiefVullenModal = ({
	open,
	setOpen,
	extraPatients,
	isLoading,
	extraUnavailablePatients,
	slotDuration,
	slotID,
	reload,
	type = "creatiefVullen",
	setIsHidden,
}) => {
	const [selectedPatients, setSelectedPatients] = useState([]);

	const fetchPatients = useFetch();

	const inviteExtraPatients = async () => {
		await fetchPatients(
			`/api/slots/${slotID}/invite-extra-patients?type=${type}`,
			{
				method: "POST",
				body: JSON.stringify({ patientIDs: selectedPatients }),
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		setOpen(false);
		setIsHidden(true);
		reload();
	};

	return (
		<Modal open={open} setOpen={setOpen} wide>
			<div className="px-8 pb-8">
				<h3 className="text-2xl font-bold">
					{type === "creatiefVullen"
						? "Patiënten met een afspraak die 5-10 minuten langer duurt"
						: "Nieuwe patiënten die in aanmerking komen"}
				</h3>
				<p className="mt-1 text-gray-600">
					{type === "creatiefVullen"
						? "Wil je deze patiënten ook uitnodigen? Let hierbij op dat je uitloop creeërt in de agenda."
						: "Wil je deze patiënten ook uitnodigen?"}
				</p>
				<SelectPatientsTable
					patients={extraPatients}
					notAvailablePatients={extraUnavailablePatients}
					isLoading={isLoading}
					selectedPatients={selectedPatients}
					setSelectedPatients={setSelectedPatients}
					autoCheckAll={true}
					creatiefVullen={true}
					slotDuration={slotDuration}
				/>
				<div className="flex justify-start gap-2 mt-4">
					<Button onClick={() => setOpen(false)} ghost>
						Annuleren
					</Button>
					<Button
						onClick={inviteExtraPatients}
						disabled={selectedPatients.length === 0}
					>
						Uitnodigen{" "}
						<span className="text-sm text-brand-200 ml-2 font-normal">
							({selectedPatients.length} patiënten)
						</span>
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default CreatiefVullenModal;
