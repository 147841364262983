import { useUser } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import Container from "../../../shared/components/UIElements/Container";
import Link from "../../../shared/components/UIElements/Link";

const RedirectOnboarding = () => {
	const navigate = useNavigate();
	const { user } = useUser();

	useEffect(() => {
		if (user) {
			window.location.href = `https://dentiva.typeform.com/to/R87GJ0kR#user_id=${user.id}`;
		}
	}, [user, navigate]);

	return (
		<Container>
			<div>
				<p className="text-lg font-semibold mb-8">
					We sturen je door naar het formulier...
				</p>

				<p className="mb-2">Gebeurt er niks?</p>
				<Link
					to={`https://dentiva.typeform.com/to/R87GJ0kR#user_id=${user.id}`}
				>
					Klik hier om handmatig de link te openen
				</Link>
			</div>
		</Container>
	);
};

export default RedirectOnboarding;
