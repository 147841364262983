import React from "react";
import Tooltip from "../../shared/components/UIElements/Tooltip";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";

const NotesTooltip = ({ notes, patientId }) => {
	return (
		<div className="text-sm">
			<Tooltip content={notes} id={patientId}>
				<ChatBubbleBottomCenterTextIcon className="size-4 text-slate-400" />
			</Tooltip>
		</div>
	);
};

export default NotesTooltip;
