import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";

import useFetch from "../../shared/hooks/useFetch";
import BehandelaarAvatar from "../components/Behandelaren/BehandelaarAvatar";
import EmptyState from "../../shared/components/Tables/EmptyState";
import { UsersIcon } from "@heroicons/react/24/outline";
import NewBehandelaar from "../components/Behandelaren/NewBehandelaar";
import SkeletonLoading from "../../shared/components/UIElements/SkeletonLoading";
import { Link, useNavigate } from "react-router-dom";
import HelpIcon from "../../shared/components/UIElements/HelpIcon";

const StatisticsPage = () => {
	const [stats, setStats] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const fetchStats = useFetch();

	const navigate = useNavigate();

	const { user } = useUser();

	useEffect(() => {
		const getStats = async () => {
			const response = await fetchStats(
				`/api/practice/user/${user.id}/behandelaars/stats`
			);
			setStats(response);
			setIsLoading(false);
		};

		getStats();
	}, [user]);

	return (
		<Container>
			<Heading className="mt-0">
				Overzicht Snelterecht lijst per behandelaar
			</Heading>
			<p className="text-gray-600 pt-2">
				Zie hoeveel patiënten er in Snelterecht staan per behandelaar,
				en hoeveel minuten de behandelingen in totaal duren.
			</p>

			{!isLoading && stats.length === 0 && (
				<div className="mt-8">
					<EmptyState
						title="Je hebt nog geen behandelaren toegevoegd"
						description="Voeg het team toe aan je account"
						icon={UsersIcon}
					>
						<NewBehandelaar />
					</EmptyState>
				</div>
			)}

			{(isLoading || stats.length > 0) && (
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
											>
												Behandelaar
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 flex items-center"
											>
												Aantal patiënten in Snelterecht
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Totale afspraak duur
											</th>
										</tr>
									</thead>
									{isLoading && (
										<SkeletonLoading
											rows={5}
											columns={3}
											components={[
												<div className="flex items-center gap-3">
													<div className="size-10 rounded-full bg-gray-200"></div>
													<div className="">
														<div className="h-5 w-40 mb-2 bg-gray-200 rounded"></div>
														<div className="h-3 w-24 bg-gray-200 rounded"></div>
													</div>
												</div>,
												<div className="h-5 w-20 bg-gray-200 rounded"></div>,
											]}
										/>
									)}
									<tbody className="divide-y divide-gray-200 bg-white">
										{stats.map((stat) => (
											<tr
												key={stat.behandelaar._id}
												className={
													stat.patientsOnWaitlist > 0
														? "hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
														: ""
												}
												onClick={() => {
													if (
														stat.patientsOnWaitlist >
														0
													) {
														navigate(
															`/patienten/all?behandelaar=${stat.behandelaar._id}`
														);
													}
												}}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
													<div className="flex items-center">
														<BehandelaarAvatar
															firstName={
																stat.behandelaar
																	.firstName
															}
															lastName={
																stat.behandelaar
																	.lastName
															}
														/>
														<div className="pl-3">
															<p className="text-black font-medium">
																{
																	stat
																		.behandelaar
																		.firstName
																}{" "}
																{
																	stat
																		.behandelaar
																		.lastName
																}
															</p>
															<p className="text-xs pt-0.5 font-normal text-gray-600">
																{
																	stat
																		.behandelaar
																		.occupation
																		.title
																}
															</p>
														</div>
													</div>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{stat.patientsOnWaitlist ===
													1
														? "1 patiënt"
														: stat.patientsOnWaitlist >
														  1
														? `${stat.patientsOnWaitlist} patiënten`
														: "—"}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{stat.totalMinutes > 0 ? (
														<>
															{Math.floor(
																stat.totalMinutes /
																	60
															) > 0 && (
																<>
																	{Math.floor(
																		stat.totalMinutes /
																			60
																	)}{" "}
																	uur
																	{stat.totalMinutes %
																		60 >
																		0 &&
																		" en "}
																</>
															)}
															{stat.totalMinutes %
																60 >
																0 && (
																<>
																	{stat.totalMinutes %
																		60}{" "}
																	minuten
																</>
															)}
														</>
													) : (
														"—"
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			)}
		</Container>
	);
};

export default StatisticsPage;
