import React, { useState, useEffect } from "react";

import { useUser } from "@clerk/clerk-react";

import useFetch from "../../shared/hooks/useFetch";
import Table from "../../shared/components/Tables/Table";
import FormattedDate from "../../shared/components/util/FormattedDate";
import MiniCopyButton from "../../shared/components/FormElements/MiniCopyButton";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";

const DeletedPatientsOverview = () => {
	const [deletedPatients, setDeletedPatients] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useUser();
	const fetchPatients = useFetch();

	const deleteReasonsDictionary = {
		not_a_patient: "Ik ben geen patiënt bij deze praktijk",
		not_requested: "Ik heb nooit gevraagd om mijn afspraak te vervroegen",
		changed_mind: "Ik wil mijn afspraak toch niet meer vervroegen",
		other: "Anders",
	};

	useEffect(() => {
		const fetchDeletedPatients = async () => {
			const response = await fetchPatients(
				`/api/patients/user/${user.id}/deleted`
			);
			setDeletedPatients(response);
			setIsLoading(false);
		};
		fetchDeletedPatients();
	}, [user, fetchPatients]);

	return (
		<Container>
			<MiniBackButton to="/patienten/all" />
			<Heading>
				Patiënten die zichzelf uit Snelterecht hebben verwijderd
			</Heading>
			<p className="text-slate-600 mt-4">
				Zodra een patiënt zichzelf uit Snelterecht verwijdert,
				anonimiseren we de gegevens in onze systemen. Je ziet dus alleen
				het patiëntnummer en de reden die de patiënt heeft opgegeven.
			</p>

			<Table data={deletedPatients} isLoading={isLoading}>
				<th
					scope="col"
					className="pr-3 py-3.5 pl-6 text-left text-sm font-semibold text-gray-900"
					accessor="patientNumber"
					render={(row) => (
						<div className="flex">
							<span className="text-black font-medium mr-2">
								{row.patientNumber}
							</span>
							<MiniCopyButton
								text={row.patientNumber}
								tooltip="Patiëntnummer kopiëren"
								tooltipId={`copy-patient-number-${row.patientNumber}`}
							/>
						</div>
					)}
				>
					Patiëntnummer
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					accessor="createdAt"
					render={(row) => <FormattedDate date={row.createdAt} />}
				>
					Datum toegevoegd
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					accessor="deletedAt"
					render={(row) => <FormattedDate date={row.deletedAt} />}
				>
					Datum verwijderd
				</th>

				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					accessor="deleteReason"
					render={(row) => deleteReasonsDictionary[row.deleteReason]}
				>
					Reden voor verwijdering
				</th>
				<th
					scope="col"
					className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
					accessor="deleteComment"
				>
					Opmerking
				</th>
			</Table>
		</Container>
	);
};

export default DeletedPatientsOverview;
