import React from "react";
import StatusPill from "./StatusPill";
import { Link } from "react-router-dom";
import FormattedDate from "../../shared/components/util/FormattedDate";

const MiniSlotItem = ({ id, date, startTime, endTime, batches, status }) => {
	//  X / X patiënten gereageerd
	const calculateResponseRate = () => {
		const invitedPatients = batches.flatMap((batch) => {
			if (new Date(batch.dateSent) < new Date()) {
				return batch.patients;
			}
			return [];
		});

		const totalInvited = invitedPatients.length;

		const readCount = invitedPatients.filter((pt) =>
			pt.patient.messages?.some((message) => {
				return (
					message.slotId === id &&
					(message.deliveryStatus === "read" ||
						message.response === "accepted" ||
						message.response === "rejected")
				);
			})
		).length;
		return `${readCount}/${totalInvited} uitnodigingen gelezen`;
	};

	return (
		<Link
			className="bg-white px-5 py-3 rounded-xl border shadow-sm border-gray-200 flex hover:shadow transition-all duration-300"
			to={`/gaten/${id}`}
		>
			<div className="w-2/5">
				<p className="font-medium" data-record="true">
					<FormattedDate date={date} />
				</p>
				<p className="text-sm text-gray-500" data-record="true">
					{startTime} - {endTime} uur
				</p>
			</div>
			<div className="w-3/5 flex items-center justify-end gap-4">
				<p className="text-sm text-gray-500" data-record="true">
					{calculateResponseRate()}
				</p>
				<StatusPill status={status} />
			</div>
		</Link>
	);
};

export default MiniSlotItem;
