import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { CheckIcon } from "@heroicons/react/16/solid";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";

import useFetch from "../../shared/hooks/useFetch";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";

const CutoffDaysInviteSettings = () => {
	const [settings, setSettings] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [cutoffDays, setCutoffDays] = useState(0);

	const updatePractice = useFetch();

	const { user } = useUser();

	const handleSubmit = async (values) => {
		await updatePractice(`/api/practice/user/${user.id}/settings`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(values),
		});
		setIsLoading(false);
		setSettings(values);

		toast.success("Voorkeuren opgeslagen", {
			theme: "light",
			hideProgressBar: true,
			autoClose: 2000,
			icon: <CheckIcon className=" text-green-500" aria-hidden="true" />,
		});
	};

	useEffect(() => {
		const getPractice = async () => {
			const practiceReq = await updatePractice(
				`/api/practice/user/${user.id}`,
				"GET"
			);

			setSettings(practiceReq.settings);
			setCutoffDays(practiceReq.settings.cutoffDaysDifference);
			setIsLoading(false);
		};

		getPractice();
	}, [updatePractice, user]);

	return (
		<div className="flex gap-4">
			<div className="">
				<Formik
					initialValues={settings}
					enableReinitialize={true}
					onSubmit={handleSubmit}
				>
					{({ values, errors, touched, handleChange }) => (
						<Form>
							<div>
								<p className="text-sm text-black font-semibold">
									Uitsluitingsgedrag
								</p>
								<p className="text-sm text-gray-500">
									Patiënten die binnen{" "}
									{cutoffDays === 1
										? "1 dag"
										: `${cutoffDays} dagen`}{" "}
									na de vrijgekomen plek al een afspraak
									hebben, ontvangen geen uitnodiging voor een
									eerdere plek.
								</p>
								<Input
									name="cutoffDaysDifference"
									type="number"
									suffix="dagen"
									required
									width="1/3"
									min={0}
									max={5}
									onChange={(e) => {
										handleChange(e);
										setCutoffDays(e.target.value);
									}}
								/>
								<Button
									type="submit"
									size="medium"
									classNames="mt-6"
								>
									Opslaan
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default CutoffDaysInviteSettings;
