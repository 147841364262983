import {
	HomeIcon,
	ArrowTrendingUpIcon,
	LifebuoyIcon,
	CheckCircleIcon,
	UserGroupIcon,
	CalendarIcon,
	BuildingOfficeIcon,
	RocketLaunchIcon,
	CogIcon,
} from "@heroicons/react/24/outline";
import NavItem from "./NavItem";

import { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";

import useFetch from "../../hooks/useFetch";
import { useTaskContext } from "../../../tasks/context/TaskContext";

const NavList = () => {
	const [notifs, setNotifs] = useState([]);
	const fetchNotifications = useFetch();
	const { user } = useUser();
	const { tasksUpdated } = useTaskContext();

	useEffect(() => {
		const getNotifications = async () => {
			const response = await fetchNotifications(
				`/api/tasks/user/${user.id}/open`
			);
			setNotifs(response.length);
		};

		getNotifications();
	}, [tasksUpdated]);

	const navigation = [
		{ name: "Dashboard", href: "/", icon: HomeIcon, classNames: "pb-9" },

		{
			name: "Patiënten",
			href: "/patienten/all",
			icon: UserGroupIcon,
		},
		{
			name: "Lege plekken",
			href: "/gaten/",
			icon: CalendarIcon,
			plusIcon: true,
			plusUrl: "/gaten/nieuw/",
		},
		{
			name: "Taken",
			href: "/taken/",
			icon: CheckCircleIcon,

			includeCounter: true,
		},

		{
			name: "Overzicht",
			href: "/overzicht/",
			classNames: "pb-9",
			icon: ArrowTrendingUpIcon,
		},

		{
			name: "Hulp nodig?",
			href: "/help",
			icon: LifebuoyIcon,
		},
		{
			name: "Instellingen",
			href: "/instellingen/",
			icon: CogIcon,
		},
	];

	return (
		<>
			{navigation.map((item) => (
				<>
					<NavItem item={item} />
				</>
			))}
		</>
	);
};

export default NavList;
