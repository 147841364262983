import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";

import { CheckCircleIcon } from "@heroicons/react/20/solid";

import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import { useChecklistItem } from "@dopt/react-checklist";

import useFetch from "../../../shared/hooks/useFetch";

import Input from "../../../shared/components/FormElements/Input";
import Select from "../../../shared/components/FormElements/Select";
import Button from "../../../shared/components/FormElements/Button";
import CheckboxGroup from "../../../shared/components/FormElements/CheckboxGroup";
import TagInput from "../../../shared/components/FormElements/TagInput";

const NewBehandelaarForm = ({ closeModal, reload }) => {
	const addTeam = useFetch();
	const { user } = useUser();
	const clItemAddedBehandelaars = useChecklistItem(
		"onboarding-v2.behandelaars-toevoegen"
	);

	const [occupations, setOccupations] = useState([]);
	const [treatments, setTreatments] = useState([]);

	const initialValues = {
		behandelaar: {
			firstName: "",
			lastName: "",
			function: "",
		},
	};

	useEffect(() => {
		const getOccupations = async () => {
			const response = await addTeam("/api/occupations", "GET");
			const formattedResponse = response.map((occupation) => ({
				label: occupation.title,
				value: occupation._id,
			}));
			setOccupations(formattedResponse);
		};

		const getTreatments = async () => {
			const response = await addTeam("/api/treatments", "GET");
			const formattedResponse = response.map((treatment) => ({
				label: treatment.name,
				value: treatment._id,
			}));
			setTreatments(formattedResponse);
		};

		getOccupations();
		getTreatments();
	}, [addTeam]);

	const handleSubmit = async (values) => {
		toast.success(`Gelukt! Je hebt een nieuwe behandelaar toegevoegd`, {
			position: "bottom-center",
			theme: "light",
			hideProgressBar: true,
			autoClose: 2000,
			icon: (
				<CheckCircleIcon
					className=" text-green-500"
					aria-hidden="true"
				/>
			),
		});

		const contactInfo = {
			userId: user.id,
			behandelaars: [values.behandelaar],
		};
		try {
			await addTeam("/api/behandelaars/", {
				method: "POST",
				body: JSON.stringify(contactInfo),
				headers: {
					"Content-Type": "application/json",
				},
			});

			reload();
			closeModal();

			clItemAddedBehandelaars.complete();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{({ values, handleChange, handleSubmit }) => (
				<Form onSubmit={handleSubmit}>
					<div className="border-b-2 pb-10 px-8 border-gray-100">
						<div className="flex gap-2">
							<Input
								label="Voornaam"
								name="behandelaar.firstName"
								value={values.behandelaar.firstName}
								onChange={handleChange}
								width="1/2"
								required
								autoComplete="off"
							/>
							<Input
								label="Achternaam"
								name="behandelaar.lastName"
								value={values.behandelaar.lastName}
								onChange={handleChange}
								width="1/2"
								autoComplete="off"
							/>
						</div>
						<Select
							label="Functie"
							name="behandelaar.function"
							value={values.behandelaar.function}
							onChange={handleChange}
							options={occupations}
							width="full"
							required
							autoComplete="off"
						/>
						<TagInput
							label="Kan ook behandelingen uitvoeren van de..."
							description="Bijvoorbeeld als de mondhygiënist ook behandelingen mag overnemen van de preventie-assistent"
							name="behandelaar.extraFuncties"
							suggestions={occupations.filter(
								(occupation) =>
									occupation.value !==
									values.behandelaar.function
							)}
							width="full"
						/>
						{/* Only show the treatments checkbox group if the functie = tandarts */}
						{(values.behandelaar.function ===
							"659fcad31bfb44314301ca81" ||
							(values.behandelaar.extraFuncties &&
								values.behandelaar.extraFuncties.some(
									(extraFunctie) =>
										extraFunctie.value ===
										"659fcad31bfb44314301ca81"
								))) && (
							<CheckboxGroup
								label="Behandelingen die de behandelaar kan uitvoeren"
								name="behandelaar.treatments"
								options={treatments}
								autoCheck={true}
								columns={2}
							/>
						)}
					</div>
					<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
						<Button type="submit" size="medium">
							Behandelaar toevoegen
						</Button>
						<Button size="medium" onClick={closeModal} ghost>
							Annuleren
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default NewBehandelaarForm;
