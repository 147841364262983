import { ArrowRightIcon } from "@heroicons/react/16/solid";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const Link = ({ children, classNames, ...props }) => {
	return (
		<RouterLink
			className={`text-brand-intense font-semibold flex items-center gap-1 hover:opacity-70 transition-all duration-200 ${classNames}`}
			{...props}
		>
			{children}
			<ArrowRightIcon className="size-4" />
		</RouterLink>
	);
};

export default Link;
