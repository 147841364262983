import { useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import useFetch from "../../shared/hooks/useFetch";

const ImportPatientsWizard = ({
	isOpen,
	onClose,
	setPatients,
	setErroredPatients,
}) => {
	const fetchBehandelaars = useFetch();
	const { user } = useUser();

	const [behandelaars, setBehandelaars] = useState([]);
	const [types, setTypes] = useState([]);
	const [fields, setFields] = useState([]);

	const translations = {
		uploadStep: {
			title: "Upload bestand",
			manifestTitle: "Gegevens die we verwachten:",
			manifestDescription:
				"(Je kan in de volgende stappen de namen van kolommen veranderen of ze verwijderen)",
			dropzone: {
				title: "Upload een .xlsx, .xls of .csv bestand",
				errorToastDescription: "Upload afgekeurd",
				activeDropzoneTitle: "Sleep je bestand hier naartoe...",
				buttonTitle: "Selecteer bestand",
				loadingTitle: "Aan het verwerken...",
			},
			selectSheet: {
				title: "Selecteer welk blad je wilt importeren",
				nextButtonTitle: "Volgende",
				backButtonTitle: "Terug",
			},
		},
		selectHeaderStep: {
			title: "Selecteer de rij met kopjes",
			nextButtonTitle: "Volgende",
			backButtonTitle: "Terug",
		},
		matchColumnsStep: {
			title: "Match kolommen bij 'Behandelaren' en 'Type behandeling'",
			nextButtonTitle: "Volgende",
			backButtonTitle: "Terug",
			userTableTitle: "Jouw gegevens",
			templateTitle: "Wordt geïmporteerd als",
			selectPlaceholder: "Selecteer kolom...",
			ignoredColumnText: "Kolom wordt genegeerd",
			subSelectPlaceholder: "Selecteer...",
			matchDropdownTitle: "Match",
			unmatched: "Unmatched",
			duplicateColumnWarningTitle:
				"Elk veld kan maar aan één kolom worden gekoppeld",
			duplicateColumnWarningDescription: "De andere wordt nu genegeerd",
		},
		validationStep: {
			title: "Check gegevens",
			nextButtonTitle: "Bevestigen & importeren",
			backButtonTitle: "Terug",
			noRowsMessage: "Geen gegevens gevonden",
			noRowsMessageWhenFiltered: "Geen van je patiënten hebben fouten",
			discardButtonTitle: "Verwijder geselecteerde rijen",
			filterSwitchTitle: "Laat alleen patiënten met fouten zien",
		},
		alerts: {
			confirmClose: {
				headerTitle: "Stoppen met importeren",
				bodyText:
					"Weet je het zeker? Je huidige voortgang wordt niet opgeslagen.",
				cancelButtonTitle: "Annuleren",
				exitButtonTitle: "Stoppen met importeren",
			},
			submitIncomplete: {
				headerTitle: "Fouten gedecteerd",
				bodyText:
					"Er zijn nog steeds rijen die fouten bevatten. Rijen met fouten worden genegeerd bij het indienen.",
				bodyTextSubmitForbidden: "Er zijn nog steeds rijen met fouten.",
				cancelButtonTitle: "Annuleren",
				finishButtonTitle: "Importeren",
			},
			submitError: {
				title: "Fout",
				defaultMessage: "Er is een fout opgetreden bij het indienen",
			},
			unmatchedRequiredFields: {
				headerTitle: "Not all columns matched",
				bodyText:
					"There are required columns that are not matched or ignored. Do you want to continue?",
				listTitle: "Columns not matched:",
				cancelButtonTitle: "Cancel",
				continueButtonTitle: "Continue",
			},
			toast: {
				error: "Error",
			},
		},
	};

	const handleSubmit = async (data, file) => {
		const patientsRes = await fetchBehandelaars(
			`/api/patients/import/user/${user.id}`,
			{
				method: "POST",
				body: JSON.stringify({ patients: data.validData }),
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		setPatients(patientsRes.patients);
		setErroredPatients(data.invalidData);
	};

	const handleRowValidation = (data, addError) => {
		if (!data.firstName && !data.lastName && !data.fullName) {
			addError("fullName", {
				message:
					"Voer de voornaam en achternaam in of de volledige naam.",
				level: "error",
			});
		} else if (data.firstName && !data.lastName) {
			addError("lastName", {
				message: "Voer zowel de voornaam als de achternaam in.",
				level: "error",
			});
		} else if (!data.firstName && data.lastName) {
			addError("firstName", {
				message: "Voer zowel de voornaam als de achternaam in.",
				level: "error",
			});
		} else if (data.firstName && data.lastName) {
			addError("fullName", {
				message:
					"Je hoeft dit veld niet in te vullen omdat je al de voor- en achternaam hebt ingevuld.",
				level: "info",
			});
		}

		if (!/^\d+$/.test(data.behandelingDuur)) {
			addError("behandelingDuur", {
				message:
					"Duur behandeling moet een getal zijn (aantal minuten). Haal spaties of andere tekens behalve cijfers weg.",
				level: "error",
			});
		}

		const selectedBehandelaar = behandelaars.find(
			(b) => b._id === data.behandelaar
		);

		if (
			selectedBehandelaar &&
			selectedBehandelaar.occupation._id === "659fcad31bfb44314301ca81" &&
			!data.treatment
		) {
			addError("treatment", {
				message:
					"Type van de behandeling is verplicht voor tandartsen.",
				level: "error",
			});
		} else {
			addError("treatment", {
				message:
					"Je hoeft het type behandeling hier niet op te geven (is alleen verplicht voor tandartsen)",
				level: "info",
			});
		}

		if (data.phoneNumber.startsWith("06")) {
			data.phoneNumber = data.phoneNumber.replace(/^06/, "+316");
		}

		const phoneRegex = /^(06|316|\+316|00316)\d{8}$/;
		if (!phoneRegex.test(data.phoneNumber)) {
			addError("phoneNumber", {
				message:
					"Mobiel telefoonnummer moet beginnen met 06, 316, +316, of 00316 gevolgd door 8 cijfers.",
				level: "error",
			});
		}

		const dateRegex = /^\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])$/;

		const alternateDateRegex =
			/^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])$/;

		const alternateDateTimeRegex =
			/^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d)\/(\d{2}) (\d{1,2}):(\d{2})$/;

		if (alternateDateRegex.test(data.datumGeplandeAfspraak)) {
			data.datumGeplandeAfspraak = data.datumGeplandeAfspraak.replace(
				/\//g,
				"-"
			);
		} else if (alternateDateTimeRegex.test(data.datumGeplandeAfspraak)) {
			const match = alternateDateTimeRegex.exec(
				data.datumGeplandeAfspraak
			);
			const month = match[1].padStart(2, "0");
			const day = match[2].padStart(2, "0");
			const year = `20${match[3]}`;
			data.datumGeplandeAfspraak = `${year}-${month}-${day}`;
		}

		if (
			data.datumGeplandeAfspraak &&
			(!dateRegex.test(data.datumGeplandeAfspraak) ||
				new Date(data.datumGeplandeAfspraak) < new Date())
		) {
			addError("datumGeplandeAfspraak", {
				message:
					"Datum geplande afspraak moet een huidige of toekomstige datum zijn in het formaat JJJJ-MM-DD en een geldige jaar, maand en dag bevatten.",
				level: "error",
			});
		}

		return data;
	};

	useEffect(() => {
		if (isOpen) {
			const getBehandelaasAndTypes = async () => {
				const behandelaarsData = await fetchBehandelaars(
					`/api/behandelaars/${user.id}`,
					"GET"
				);

				setBehandelaars(behandelaarsData);

				const behandelaarsFormatted = behandelaarsData.map(
					({ firstName, lastName, _id }) => ({
						label: `${firstName} ${lastName}`,
						value: _id,
					})
				);

				const treatmentTypesData = await fetchBehandelaars(
					`/api/treatments`,
					"GET"
				);
				const treatmentTypesFormatted = treatmentTypesData.map(
					({ name, _id }) => ({
						label: name,
						value: _id,
					})
				);
				setTypes(treatmentTypesFormatted);

				setFields([
					{
						label: "Voornaam",
						key: "firstName",
						alternateMatches: [
							"Voornaam",
							"voornaam",
							"Initialen",
							"Voorletters",
							"voorletters",
							"initialen",
						],
						fieldType: { type: "input" },
						example: "Jan",
						// validations: [
						// 	{
						// 		rule: "required",
						// 		errorMessage: "Voornaam is verplicht",
						// 		level: "error",
						// 	},
						// ],
					},
					{
						label: "Achternaam",
						key: "lastName",
						alternateMatches: [
							"Achternaam",
							"achternaam",
							"Familienaam",
							"familienaam",
						],
						fieldType: { type: "input" },
						example: "Janssen",
						// validations: [
						// 	{
						// 		rule: "required",
						// 		errorMessage: "Achternaam is verplicht",
						// 		level: "error",
						// 	},
						// ],
					},
					{
						label: "Volledige naam",
						key: "fullName",
						alternateMatches: [
							"Volledige naam",
							"volledige naam",
							"Patientnaam",
							"Patiëntnaam",
						],
						fieldType: { type: "input" },
						example: "Jan Janssen",
					},
					{
						label: "Telefoonnummer",
						key: "phoneNumber",
						alternateMatches: ["Telefoonnummer", "telefoonnummer"],
						fieldType: { type: "input" },
						example: "+31 6 12345678",
						validations: [
							{
								rule: "regex",
								errorMessage: "Invalid phone number format",
								level: "error",
								regex: "^(\\+\\d{1,3}[- ]?)?\\d{10}$",
							},
						],
					},
					{
						label: "Patiëntnummer",
						key: "patientnummer",
						alternateMatches: [
							"Patiëntnummer",
							"patiëntnummer",
							"Patientnummer",
							"patientnummer",
							"ID",
							"id",
							"Dossiernummer",
						],
						fieldType: { type: "input" },
						example: "1234-1",
						validations: [
							{
								rule: "required",
								errorMessage: "Patiëntnummer is verplicht",
								level: "error",
							},
							{
								rule: "unique",
								errorMessage: "Patiëntnummer moet uniek zijn",
								level: "error",
							},
						],
					},

					{
						label: "Behandelaar",
						alternateMatches: [
							"Behandelaar",
							"behandelaar",
							"Medewerker",
						],
						key: "behandelaar",
						fieldType: {
							type: "select",
							options: behandelaarsFormatted,
						},
						validations: [
							{
								rule: "required",
								errorMessage: "Behandelaar is verplicht",
								level: "error",
							},
						],
					},
					{
						label: "Type behandeling",
						key: "treatment",
						alternateMatches: [
							"Type behandeling",
							"type behandeling",
							"Afspraaksoort",
						],
						fieldType: {
							type: "select",
							options: treatmentTypesFormatted,
						},
					},
					{
						label: "Mogen andere behandelaars de behandeling ook uitvoeren?",
						key: "otherBehandelaars",
						alternateMatches: [
							"Mogen andere behandelaars deze behandeling ook uitvoeren?",
							"mogen andere behandelaars deze behandeling ook uitvoeren?",
							"Mogen andere behandelaars de behandeling ook uitvoeren?",
							"mogen andere behandelaars de behandeling ook uitvoeren?",
						],
						fieldType: { type: "checkbox" },
					},
					{
						label: "Datum geplande behandeling",
						key: "datumGeplandeAfspraak",
						alternateMatches: [
							"Datum geplande behandeling",
							"datum geplande behandeling",
							"afspraak datum",
							"afspraak",
							"datum",
							"Afspraakdatum",
							"Datum afspraak",
							"datum afspraak",
							"Datum geplande afspraak",
							"datum geplande afspraak",
							"Datum geplande afspraak (JJJJ-MM-DD)",
						],
						fieldType: { type: "input" },
						example: "2023-12-31",
					},
					{
						label: "Duur behandeling in minuten",
						key: "behandelingDuur",
						alternateMatches: [
							"Duur behandeling in minuten",
							"duur behandeling in minuten",
							"Duur behandeling",
							"duur behandeling",
							"Duur",
							"duur",
							"Behandeling duur",
							"behandeling duur",
							"Duur afspraak",
							"duur afspraak",
							"Afspraakduur",
							"afspraakduur",
							"Duur afspraak (in minuten)",
						],
						fieldType: { type: "input" },
						example: "60",
						validations: [
							{
								rule: "required",
								errorMessage: "Duur behandeling is verplicht",
								level: "error",
							},
							{
								rule: "regex",
								errorMessage:
									"Duur behandeling moet een getal zijn",
								level: "error",
								regex: "^\\d+$",
							},
						],
					},

					{
						label: "Spoed?",
						alternateMatches: ["Spoed", "spoed"],
						key: "priority",
						fieldType: { type: "checkbox" },
					},
					{
						label: "Opmerkingen",
						key: "notes",
						alternateMatches: [
							"Opmerkingen",
							"opmerkingen",
							"Balieopmerking",
						],
						fieldType: { type: "input" },
					},
				]);
			};

			getBehandelaasAndTypes();
		}
	}, [isOpen, fetchBehandelaars, user.id]);

	return (
		<ReactSpreadsheetImport
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={handleSubmit}
			fields={fields}
			translations={translations}
			isNavigationEnabled={true}
			allowInvalidSubmit={false}
			// autoMapSelectValues={true}
			rowHook={handleRowValidation}
		/>
	);
};

export default ImportPatientsWizard;
