import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../shared/hooks/useFetch";

import SlotsTableItem from "./SlotsTableItem";
import { useUser } from "@clerk/clerk-react";
import SkeletonLoading from "../../shared/components/UIElements/SkeletonLoading";
import EmptyState from "../../shared/components/Tables/EmptyState";
import { CalendarIcon } from "@heroicons/react/24/outline";

const SlotsTable = ({
	initialSlots = [],
	initialIsLoading,
	itemsClickable = true,
	shouldFetch = true,
	archive = false,
}) => {
	const fetchSlots = useFetch();
	const { user } = useUser();
	const [slots, setSlots] = useState(initialSlots);
	const [isLoading, setIsLoading] = useState(initialIsLoading || true);

	const reload = useCallback(async () => {
		if (shouldFetch) {
			const fetchedSlots = await fetchSlots(`/api/slots/user/${user.id}`);
			setSlots(fetchedSlots);
		}
		setIsLoading(false);
	}, [fetchSlots, user.id, shouldFetch]);

	useEffect(() => {
		if (shouldFetch) {
			reload();
		} else {
			setSlots(initialSlots);
			setIsLoading(initialIsLoading);
		}
	}, [initialIsLoading, reload, shouldFetch, slots.length]);

	if (!isLoading && slots.length === 0) {
		return (
			<EmptyState
				title="Je hebt geen openstaande lege plekken"
				description="Meld plekken die zijn vrijgekomen om je agenda te vullen."
				icon={CalendarIcon}
				buttonText="Nieuwe lege plek"
				buttonUrl="/gaten/nieuw"
			/>
		);
	}

	return (
		<>
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Datum van lege plek
										</th>
										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Behandelaar
										</th>

										<th
											scope="col"
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Status
										</th>
										{!archive && (
											<th
												scope="col"
												className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Gereageerd
											</th>
										)}
									</tr>
								</thead>
								{isLoading && (
									<SkeletonLoading
										rows={8}
										columns={!archive ? 4 : 3}
										components={[
											<div className="">
												<div className="h-5 w-40 mb-2 bg-gray-200 rounded"></div>
												<div className="h-3 w-24 bg-gray-200 rounded"></div>
											</div>,

											<div className="flex items-center gap-3">
												<div className="size-10 rounded-full bg-gray-200"></div>

												<div className="h-4 w-24 bg-gray-200 rounded"></div>
											</div>,
											!archive && (
												<div className="h-4 w-12 bg-gray-200 rounded-full"></div>
											),
										]}
									/>
								)}
								<tbody className="bg-white divide-y divide-gray-200">
									{slots.map((slot) => (
										<SlotsTableItem
											key={slot._id}
											slotID={slot._id}
											behandelaarFirstName={
												slot.behandelaar.firstName
											}
											behandelaarLastName={
												slot.behandelaar.lastName
											}
											date={slot.date}
											startTime={slot.startTime}
											endTime={slot.endTime}
											status={slot.status}
											reload={reload}
											batches={slot.batches}
											itemClickable={itemsClickable}
											archive={archive}
											claimedBy={
												slot.status === "claimed"
													? slot.claimedBy
															.patientNumber
													: null
											}
										/>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SlotsTable;
