import React from "react";
import { useField } from "formik";

const Select = ({ label, ...props }) => {
	const [field, meta] = useField(props);

	const widthClasses = {
		"1/2": "w-1/2", // 50%
		"1/3": "w-1/3", // 33.333333%
		"2/3": "w-2/3", // 66.666667%
		"1/4": "w-1/4", // 25%
		"2/4": "w-2/4", // 50%
		"3/4": "w-3/4", // 75%
		"1/5": "w-1/5", // 20%
		"2/5": "w-2/5", // 40%
		"3/5": "w-3/5", // 60%
		"4/5": "w-4/5", // 80%
		"1/6": "w-1/6", // 16.666667%
		"2/6": "w-2/6", // 33.333333%
		"3/6": "w-3/6", // 50%
		"4/6": "w-4/6", // 66.666667%
		"5/6": "w-5/6", // 83.333333%
		"1/12": "w-1/12", // 8.333333%
		"5/12": "w-5/12", // 41.666667%
		"7/12": "w-7/12", // 58.333333%
		full: "w-full", // 100%
		screen: "w-screen", // 100vw
		min: "w-min", // min-content
	};

	return (
		<div className={widthClasses[props.width] || widthClasses.full}>
			<div
				className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}
			>
				<div className="col-span-full">
					<label
						htmlFor={props.id || props.name}
						className="block text-sm font-medium leading-6
					text-gray-900"
					>
						{label}
					</label>
					<div className="mt-2">
						<select
							{...field}
							{...props}
							className={`${props.selectClassnames} block w-full rounded-md border-0 pr-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6`}
						>
							<option value="" selected disabled>
								Selecteren...
							</option>
							{props.options.map((option, index) => (
								<option
									key={index}
									value={option.value || option}
								>
									{option.label || option}
								</option>
							))}
						</select>
						{meta.touched && meta.error ? (
							<div className="text-orange-600 text-sm pt-2">
								{meta.error}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Select;
