import { AdjustmentsHorizontalIcon } from "@heroicons/react/16/solid";
import CutoffDaysInviteSettings from "../../../practice/components/CutoffDaysInviteSettings";
import PracticeInfoForm from "../../../practice/components/PracticeInfoForm";
import Heading from "../../../shared/components/UIElements/Heading";

const ManagePractices = () => {
	return (
		<>
			<Heading size="2">Praktijkinformatie</Heading>
			<p className="pt-2 text-gray-600">
				Deze gegevens zien je patiënten ook in de WhatsApp berichten en
				op de bevestigingspagina.
			</p>
			<div className="pt-6 w-fit">
				<PracticeInfoForm />
			</div>

			{/* <div className="pt-10 mt-12 border-t border-gray-200">
				<div className="flex items-center">
					<AdjustmentsHorizontalIcon className="size-5 mr-2 mt-2 text-brand-500" />
					<Heading size="3">Voorkeuren</Heading>
				</div>
				<CutoffDaysInviteSettings />
			</div> */}
		</>
	);
};

export default ManagePractices;
