import React, { useEffect } from "react";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import {
	ArrowPathIcon,
	ArrowUturnRightIcon,
	ChatBubbleBottomCenterIcon,
	ChatBubbleOvalLeftIcon,
} from "@heroicons/react/16/solid";
import Button from "../../shared/components/FormElements/Button";
import { useClerk } from "@clerk/clerk-react";
import AuthPromo from "../../users/components/AuthPromo";
import AuthFooter from "../../users/components/AuthFooter";
import MainLogo from "../../shared/components/Branding/MainLogo";
import posthog from "posthog-js";
import mixpanel from "mixpanel-browser";

const NoActiveSubscription = () => {
	const { signOut } = useClerk();

	useEffect(() => {
		posthog.capture(
			"🚨 Gebruiker probeert Snelterecht te gebruiken zonder actief abonnement"
		);
		// mixpanel.track(
		// 	"🚨 Gebruiker probeert Snelterecht te gebruiken zonder actief abonnement"
		// );
	}, []);

	return (
		<div className="bg-white h-screen grid lg:grid-cols-2">
			<div className="grid content-between px-12 sm:px-24">
				<MainLogo className="mt-12" />

				<div>
					<ArrowPathIcon className="size-10 text-brand-intense mb-4" />
					<Heading size="2">
						Het lijkt erop dat je geen actief abonnement hebt op
						Snelterecht
					</Heading>
					<p className="text-lg text-slate-500 mt-6">
						Klopt dit niet? Neem contact met ons op via de chat
						rechtsonder, dan lossen we het voor je op.
					</p>
					<div className="flex gap-3 mt-5">
						<Button
							type="button"
							onClick={() => {
								window.Intercom("show");
							}}
							iconPrefix={ChatBubbleOvalLeftIcon}
						>
							Open de chat
						</Button>
						<Button
							type="button"
							onClick={() => {
								signOut();
							}}
							ghost
							iconPrefix={ArrowUturnRightIcon}
						>
							Uitloggen
						</Button>
					</div>
				</div>
				<div className="mb-12 sm:flex justify-between">
					<AuthFooter />
				</div>
			</div>
		</div>
	);
};

export default NoActiveSubscription;
