import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useUser } from "@clerk/clerk-react";
import { useChecklistItem } from "@dopt/react-checklist";

import { CheckIcon, PencilSquareIcon } from "@heroicons/react/16/solid";

import useFetch from "../../shared/hooks/useFetch";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import Modal from "../../shared/components/UIElements/Modal";
import PracticeInfoButton from "../../users/components/onboarding/PracticeInfoButton";
import SkeletonLoadingItem from "../../shared/components/UIElements/SkeletonLoadingItem";

const PracticeInfoForm = () => {
	const [practice, setPractice] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const clItemPracticeInfo = useChecklistItem(
		"onboarding.praktijkinformatie"
	);

	const updatePractice = useFetch();

	const { user } = useUser();

	const handleSubmit = async (values) => {
		await updatePractice(`/api/practice/user/${user.id}`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(values),
		});
		setIsLoading(false);
		setIsModalOpen(false);
		setPractice(values);

		await user.update({
			unsafeMetadata: {
				...user.unsafeMetadata,
				praktijkNaam: values.name,
			},
		});

		toast.success("Praktijkgegevens gewijzigd", {
			position: "bottom-center",
			theme: "light",
			hideProgressBar: true,
			autoClose: 2000,
			icon: <CheckIcon className=" text-green-500" aria-hidden="true" />,
		});

		clItemPracticeInfo.complete();
	};

	useEffect(() => {
		const getPractice = async () => {
			const practiceReq = await updatePractice(
				`/api/practice/user/${user.id}`,
				"GET"
			);

			setPractice(practiceReq);
			setIsLoading(false);
		};

		getPractice();
	}, [updatePractice, user]);

	if (isLoading)
		return (
			<SkeletonLoadingItem
				width="w-44 h-48"
				color="gray-200"
				rounded="2xl"
			/>
		);

	return (
		<>
			{practice.name && (
				<div className="bg-white border-slate-300 border rounded-2xl text-gray-600 text-sm">
					<div className="pl-6 pr-10 pb-4 pt-6">
						<p className="font-semibold text-black">
							{practice.name}
						</p>
						<p>{practice.address}</p>
						<p>
							{practice.postCode} {practice.city}
						</p>
						<p className="pt-4">{practice.phoneNumber}</p>
					</div>
					<div className="px-6 py-4 border-slate-300 border-t">
						<button
							onClick={() => setIsModalOpen(true)}
							className="text-brand-600 flex items-center gap-1 font-semibold hover:text-brand-700 transition-all duration-200"
						>
							Wijzigen
							<PencilSquareIcon className="size-4" />
						</button>
					</div>
				</div>
			)}
			{!practice.name && !isLoading && (
				<>
					{/* <Button onClick={() => setIsModalOpen(true)}>
						Voeg praktijkgegevens toe
					</Button> */}
					<PracticeInfoButton />
				</>
			)}
			<Modal
				open={isModalOpen}
				setOpen={setIsModalOpen}
				includeCloseButton={false}
			>
				<h3 className="px-8 font-bold text-xl pt-8">
					Wijzig praktijkgegevens
				</h3>
				<Formik initialValues={practice} onSubmit={handleSubmit}>
					{({ errors, touched }) => (
						<Form autoComplete="off">
							<div className="border-b-2 pb-10 px-8 border-gray-100">
								<div className="flex gap-x-2">
									<Input
										label="Praktijknaam"
										name="name"
										type="text"
										// width="1/2"
										required
										description="Dit wordt gebruikt in de WhatsApp berichten. Gebruik dus in plaats van 'Praktijknaam B.V.' liever 'Praktijknaam'."
										autoComplete="off"
									/>
								</div>
								<Input
									label="Adres"
									name="address"
									type="text"
									required
									autoComplete="off"
								/>
								<div className="flex gap-x-2">
									<Input
										label="Postcode"
										name="postCode"
										type="text"
										width="1/2"
										required
										autoComplete="off"
									/>
									<Input
										label="Stad"
										name="city"
										type="text"
										width="1/2"
										required
										autoComplete="off"
									/>
								</div>
								<div className="flex gap-x-2">
									<Input
										label="Telefoonnummer"
										name="phoneNumber"
										type="text"
										width="1/2"
										required
										autoComplete="off"
									/>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
								<Button type="submit" size="medium">
									Praktijk updaten
								</Button>
								<Button
									size="medium"
									type="button"
									onClick={() => setIsModalOpen(false)}
									ghost
								>
									Annuleren
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
};

export default PracticeInfoForm;
