import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../shared/hooks/useFetch";
import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import MiniBackButton from "../../shared/components/FormElements/MiniBackButton";
import CopyText from "../../shared/components/FormElements/CopyText";
import CheckList from "../../shared/components/UIElements/CheckList";
import MiniCopyButton from "../../shared/components/FormElements/MiniCopyButton";

const PatientInfoEmail = () => {
	const [practice, setPractice] = useState("");
	const [email, setEmail] = useState("");

	const { user } = useUser();
	const fetchPractice = useFetch();

	useEffect(() => {
		const getPractice = async () => {
			const practiceReq = await fetchPractice(
				`/api/practice/user/${user.id}`,
				"GET"
			);
			setPractice(practiceReq);
		};

		getPractice();
	}, [fetchPractice, user]);

	const encodedPractice = encodeURIComponent(practice.name);
	const link = `https://snelterecht.nl/patienten/?pt=${encodedPractice}`;

	const generateEmailContent = (practice) => {
		return `Beste patiënt,

U staat bij ons op de wachtlijst. Graag brengen wij u op de hoogte van de volgende wijziging. Voor uw en voor ons gemak maken wij vanaf heden gebruik van een digitale wachtlijst. Zo kunnen wij u sneller bereiken en kunt u gelijk bekijken of u in de gelegenheid bent om op de voorgestelde datum en tijd naar de praktijk te komen. U krijgt bericht zodra er een plekje bij uw behandelaar is vrijgekomen. In het vervolg kunt u een WhatsApp bericht van ons verwachten waarbij wij uitsluitend gebruik maken van uw naam en telefoonnummer. Deze gebruiken wij reeds voor onze huidige/eerdere wachtlijst en dienst. Meer informatie kunt u vinden op ${link}. Mocht u nog andere vragen of bezwaar hebben op deze wijziging, dan kunt u ons bereiken per telefoon op ${
			practice.phoneNumber || "...."
		} of kunt u mailen naar ${practice.settings?.emailAddress || "...."}

Met vriendelijke groet,
Team ${practice.name}`;
	};

	useEffect(() => {
		if (practice) {
			setEmail(generateEmailContent(practice));
		}
	}, [practice]);

	return (
		<Container>
			<MiniBackButton to="/help" />
			<Heading>Informatiemail voor patiënten</Heading>
			<p className="text-gray-600 mt-2 mb-6">
				Deze mail kun je sturen naar patiënten die nu al op je snel
				bellen lijst staan, om ze te informeren dat jullie ze vanaf nu
				per WhatsApp op de hoogte houden.
			</p>

			<CheckList
				items={[
					"Voor patiënten die nu op de oude snel bellen lijst staan",
				]}
			/>

			<div className="mt-10">
				{practice && (
					<p className="text-slate-700 w-1/2 bg-white p-4 rounded-xl border-slate-300 border shadow-sm">
						{generateEmailContent(practice)
							.split("\n")
							.map((line, index) => (
								<React.Fragment key={index}>
									{line}
									<br />
								</React.Fragment>
							))}
					</p>
				)}
			</div>
			<MiniCopyButton
				text={email}
				includeText={true}
				includeLabel={true}
				label="Kopieer e-mail"
				classNames="mt-4 scale-110"
			/>
		</Container>
	);
};

export default PatientInfoEmail;
