import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
	const { pathname } = useLocation();
	const pathsToIgnore = ["/patienten"];

	useEffect(() => {
		if (!pathsToIgnore.some((path) => pathname.startsWith(path))) {
			window.scrollTo(0, 0);
		}
	}, [pathname]);

	return null;
}
