import React, { useState } from "react";
import {
	CheckIcon,
	EllipsisHorizontalIcon,
	ExclamationTriangleIcon,
	XMarkIcon,
} from "@heroicons/react/16/solid";
import classNames from "classnames";

import PatientDetail from "../../patients/components/PatientDetail";
import CreatiefVullenNotice from "./CreatiefVullenNotice";
import NewEligiblePatientsNotice from "./NewEligiblePatientsNotice";
import BatchTimelineItem from "./BatchTimelineItem";
import PriorityStar from "../../patients/components/PriorityStar";

const BatchTimeline = ({
	batches = [],
	sent = false,
	claimedBy = {},
	slotId,
	slotDuration,
	reload,
	slotDate,
	hideCreatiefVullenNotice = false,
	hideNewPatientsNotice = false,
}) => {
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [isHidden, setIsHidden] = useState(false);

	const BATCH_TIME_BETWEEN = 10;

	return (
		<div className="">
			<ul className="">
				{batches.map((batch, batchIdx) => (
					<div className="" key={batch.id}>
						<li className="relative flex gap-x-4 pb-12">
							<div>
								<div className="w-[90px]">
									{sent && (
										<p className="text-sm text-right text-gray-600 flex items-end justify-end">
											{batch.dateSent
												? new Date(
														batch.dateSent
												  ).toLocaleTimeString([], {
														hour: "2-digit",
														minute: "2-digit",
												  })
												: "Uitnodiging ingepland"}
											{batch.priority && (
												<PriorityStar tooltip="Patiënten met spoed" />
											)}
										</p>
									)}
									{!sent && (
										<p className="text-sm text-right text-gray-600 flex items-end justify-end">
											{batchIdx === 0
												? "Nu"
												: `${
														batchIdx *
														BATCH_TIME_BETWEEN
												  } min later`}
											{batch.priority && (
												<PriorityStar tooltip="Patiënten met spoed" />
											)}
										</p>
									)}
								</div>
								<div
									className={classNames(
										batchIdx === batches.length - 1
											? "h-12"
											: "-bottom-12",
										"absolute left-[105px] top-0 flex w-6 justify-center z-0"
									)}
								>
									<div className="w-px bg-gray-200" />
								</div>
								<div className="flex h-6 w-6 flex-none items-center justify-center bg-transparent absolute left-[105px] top-0">
									<div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
								</div>
							</div>
							<div className="flex h-6 w-6 flex-none items-center justify-center bg-white">
								<div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-0 ring-gray-300" />
							</div>
							<ul className="flex-auto py-0.5 text-sm text-gray-900 font-regular flex flex-wrap gap-4 -mt-3">
								{batch.patients.map((patient) => (
									<BatchTimelineItem
										key={patient.patient._id}
										patient={patient}
										slotId={slotId}
										batch={batch}
										slotDate={slotDate}
										sent={sent}
										claimedBy={claimedBy}
										onSelectPatient={setSelectedPatient}
									/>
								))}
							</ul>
						</li>
					</div>
				))}
				{sent && !hideCreatiefVullenNotice && !isHidden && (
					<>
						<CreatiefVullenNotice
							slotID={slotId}
							batches={batches}
							slotDuration={slotDuration}
							reload={reload}
							setIsHidden={setIsHidden}
						/>
					</>
				)}

				{sent && !hideNewPatientsNotice && (
					<NewEligiblePatientsNotice
						slotID={slotId}
						batches={batches}
						slotDuration={slotDuration}
						reload={reload}
					/>
				)}
			</ul>
			{sent && (
				<PatientDetail
					patientId={selectedPatient}
					open={!!selectedPatient}
					setOpen={() => setSelectedPatient(null)}
					onClose={() => setSelectedPatient(null)}
					onPreviewPage={true}
					reload={() => {}}
				/>
			)}
		</div>
	);
};

export default BatchTimeline;
