import React from "react";
import { useField, useFormikContext } from "formik";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { PlusIcon } from "@heroicons/react/16/solid";
import Tooltip from "../UIElements/Tooltip";

const PhoneInputField = ({
	label,
	allowMultiple = false,
	onAddNewNumber,
	phoneIndex,
	maxAmountOfPhones = 2,
	...props
}) => {
	const [field, meta, helpers] = useField(props);
	const { setFieldValue } = useFormikContext();

	const handleChange = (value) => {
		setFieldValue(props.name, value);
	};

	return (
		<div
			className={`mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ${props.className}`}
		>
			<div className="col-span-full">
				<div className="flex justify-between">
					<label
						htmlFor={props.id || props.name}
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						{label}
					</label>
					{allowMultiple && phoneIndex < maxAmountOfPhones && (
						<>
							<Tooltip
								content="Voeg een extra telefoonnummer toe (optioneel)"
								id="new-phone-number"
								place="top-end"
							>
								<button
									className="mt-0.5"
									onClick={onAddNewNumber}
									tabIndex={-1} // Prevents the button from being focused during tab navigation
								>
									<PlusIcon className="size-5 text-brand-500" />
								</button>
							</Tooltip>
						</>
					)}
				</div>
				<div className="mt-2 relative rounded-md shadow-sm bg-white">
					<PhoneInput
						{...field}
						{...props}
						onChange={handleChange}
						defaultCountry="NL"
						autoComplete="off"
						countrySelectProps={{ unicodeFlags: true }}
						className="block w-full border-0 py-[1px] text-gray-900 rounded-md ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
					/>
				</div>
				{meta.touched && meta.error ? (
					<div className="text-orange-600 text-sm pt-2">
						{meta.error}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PhoneInputField;
