import React from "react";
import { useField } from "formik";

const Textarea = ({ label, description, ...props }) => {
	const [field, meta] = useField(props);

	const handlePaste = (text) => {
		props.setFieldValue(props.name, text);
	};

	return (
		<div className={props.width ? "w-" + props.width : "w-full"}>
			<div
				className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}
			>
				<div className="col-span-full">
					<div className="">
						<label
							htmlFor={props.id || props.name}
							className="block text-sm font-medium leading-6
					text-gray-900"
						>
							{label}
							{props.optional && (
								<span className="text-xs font-normal ml-1 text-gray-500">
									(optioneel)
								</span>
							)}
						</label>
						{description && (
							<p
								className="text-sm leading-5 text-gray-500 pb-2 pt-1"
								dangerouslySetInnerHTML={{
									__html: description,
								}}
							></p>
						)}
					</div>
					<div className="mt-2">
						<textarea
							{...field}
							{...props}
							className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6`}
						/>
						{meta.touched && meta.error ? (
							<div className="error">{meta.error}</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Textarea;
