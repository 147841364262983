import React from "react";
import SelectBehandelaar from "../../practice/components/Behandelaren/SelectBehandelaar";
import DatePicker from "../../shared/components/FormElements/DatePicker";
import TimeRangePicker from "../../shared/components/FormElements/TimeRangePicker";

const FormFields = ({ errors, touched }) => (
	<>
		<div className="flex gap-x-2">
			<SelectBehandelaar
				label="Behandelaar"
				name="behandelaar"
				width="1/2"
				required
			/>
		</div>
		<DatePicker
			label="Datum"
			name="unformattedDate"
			required
			error={errors.unformattedDate && touched.unformattedDate}
		/>
		<TimeRangePicker
			label="Start- en eindtijd"
			name="time"
			required
			error={errors.time && touched.time}
		/>
	</>
);

export default FormFields;
