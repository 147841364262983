import { StarIcon } from "@heroicons/react/16/solid";
import React from "react";
import Tooltip from "../../shared/components/UIElements/Tooltip";

const PriorityStar = ({ id = "", tooltip = "Patiënt met spoed" }) => {
	return (
		<div className="ml-1.5">
			<Tooltip id={`priority-star-${id}`} content={tooltip}>
				<StarIcon className="h-5 w-5 text-yellow-500" />
			</Tooltip>
		</div>
	);
};

export default PriorityStar;
